import { TextField } from '@material-ui/core';
import React from 'react';
import { StepperObjectContentProps } from './GeneralSetup';

export type HouseName = {
  houseName: string;
};

export default function SetHouseName({
  values,
  errors,
  handleChange,
}: StepperObjectContentProps<HouseName>) {
  return (
    <TextField
      autoFocus
      fullWidth
      required
      margin="normal"
      variant="outlined"
      name="houseName"
      label="Alias für mein Zuhause"
      value={values.houseName}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleChange({ ...values, houseName: event.target.value })
      }
      error={errors.houseName !== undefined}
      helperText={errors.houseName}
    />
  );
}
