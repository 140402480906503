import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import {
  Container,
  CircularProgress,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import { Formik, FormikErrors } from 'formik';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import {
  ScanForThingsData,
  SCAN_FOR_THINGS_QUERY,
  Thing,
  THING_ATTRIBUTES,
  CREATE_THING_MUTATION,
  CreateThingData,
  CreateThingInput,
  ListThingsData,
  LIST_THING_QUERY,
  extendedThingType,
} from '../../api/things';
import * as Constants from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    loadingError: {
      marginTop: '5%',
    },
    container: {
      paddingBottom: theme.spacing(4),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

const selectNewRoom = '#newRoom';

type CreateThingUserInput = {
  thingName: string;
  selectedRoom: string;
  newRoom: string;
};

export default function CreateThing() {
  const classes = useStyles();

  const history = useHistory();
  const historyGoSettings = () => history.push('/app/settings');

  const {
    loading: scanLoading,
    error: scanError,
    data: scanData,
    refetch: scanRefetch,
    networkStatus: scanNetworkStatus,
  } = useQuery<ScanForThingsData>(SCAN_FOR_THINGS_QUERY, {
    // fetchPolicy: 'network-only', // ERROR: infinite loop
    notifyOnNetworkStatusChange: true,
  });
  if (scanError) console.log(JSON.stringify(scanError));

  const { loading: listThingsLoading, error: listThingsError, data: listThingsData } = useQuery<
    ListThingsData,
    undefined
  >(React.useMemo(() => LIST_THING_QUERY, []));
  if (listThingsError) console.log(JSON.stringify(listThingsError));

  const [openThing, setOpenThing] = React.useState<Thing | undefined>(undefined);
  const [refetched, setRefetched] = React.useState(false);

  const handleCloseThing = () => {
    setOpenThing(undefined);
  };

  const [createThing, { error: createThingError }] = useMutation<CreateThingData, CreateThingInput>(
    CREATE_THING_MUTATION,
    {
      update(cache, { data }) {
        const newThing = data?.createThing;
        if (!newThing) return;
        cache.modify({
          fields: {
            listThings(existingThingRefs = [], { readField }) {
              const newThingRef = cache.writeFragment({
                data: newThing,
                fragment: THING_ATTRIBUTES,
              });
              if (existingThingRefs.some((ref: any) => readField('id', ref) === newThing.id))
                return existingThingRefs;
              return [...existingThingRefs, newThingRef];
            },
          },
        });
      },
    }
  );

  const submitThingData = async (input: CreateThingUserInput) => {
    if (openThing === undefined) {
      console.log('Submit Error openThing undefined!');
      setOpenThing(undefined);
      return;
    }
    console.log('Submit', input, openThing);
    setRefetched(false);
    try {
      const res = await createThing({
        variables: {
          id: openThing.id,
          name: input.thingName,
          roomName: input.selectedRoom !== selectNewRoom ? input.selectedRoom : input.newRoom,
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
    setOpenThing(undefined);
    try {
      await scanRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const validate = (input: CreateThingUserInput) => {
    const errors: FormikErrors<CreateThingUserInput> = {};
    if (input.thingName === '') errors.thingName = 'Name des Geräts eingeben.';
    else if (input.thingName.length > 50) errors.thingName = 'Maximal 50 Zeichen.';
    else if (!Constants.LetterNumberSpaceRegex.test(input.thingName))
      errors.thingName = 'Nur Buchstaben, Zahlen und Leerzeichen zulässig.';
    if (input.selectedRoom === selectNewRoom) {
      if (input.newRoom === '') errors.newRoom = 'Neuen Raumnamen Eingeben.';
      else if (!Constants.LetterNumberSpaceRegex.test(input.newRoom))
        errors.newRoom = 'Nur Buchstaben, Zahlen und Leerzeichen zulässig.';
    } else if (!Constants.LetterNumberSpaceRegex.test(input.selectedRoom))
      errors.selectedRoom = 'Unzulässiger Raumname';
    return errors;
  };

  async function triggerScanRefetch() {
    console.log('refetching scan data');
    setRefetched(true);
    try {
      await scanRefetch();
    } catch (e) {
      console.log(e);
    }
  }

  // useEffect(() => {
  //   scanRefetch();
  // }, []);

  return (
    <>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={historyGoSettings} aria-label="close">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Gerät Hinzufügen
        </Typography>
        <IconButton edge="start" color="inherit" aria-label="close" onClick={triggerScanRefetch}>
          <RefreshIcon />
        </IconButton>
      </Toolbar>
      <Container maxWidth="lg" className={classes.container}>
        {(scanLoading || scanNetworkStatus === NetworkStatus.refetch || listThingsLoading) && (
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={classes.loadingError}
          >
            <CircularProgress />
          </div>
        )}
        {(scanError || listThingsError || (createThingError && !refetched)) && (
          <Alert
            severity="error"
            className={classes.loadingError}
            action={
              <Button color="inherit" size="small" onClick={triggerScanRefetch}>
                Retry
              </Button>
            }
          >
            <AlertTitle>Error</AlertTitle>
            {scanError?.message ?? listThingsError?.message ?? createThingError?.message}
          </Alert>
        )}
        {!listThingsError &&
          scanNetworkStatus === NetworkStatus.ready &&
          scanData &&
          scanData.scanForThings.length === 0 && (
            <Alert
              severity="info"
              className={classes.loadingError}
              action={
                <Button color="inherit" size="small" onClick={triggerScanRefetch}>
                  Retry
                </Button>
              }
            >
              Es konte kein neues Gerät gefunden werden.
            </Alert>
          )}
        {listThingsData &&
          scanNetworkStatus === NetworkStatus.ready &&
          scanData &&
          scanData.scanForThings.length > 0 && (
            <>
              <List>
                {scanData.scanForThings.map((thing, idx) => {
                  return (
                    <div key={thing.id}>
                      <ListItem button onClick={() => setOpenThing(thing)}>
                        <ListItemIcon>
                          <WbIncandescentIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${extendedThingType(thing)} (${thing.adapter})`}
                          secondary={thing.adapterThingID}
                        />
                      </ListItem>
                      {idx !== scanData.scanForThings.length - 1 && <Divider />}
                    </div>
                  );
                })}
              </List>
              <Dialog open={openThing !== undefined} onClose={handleCloseThing}>
                <Formik
                  initialValues={{ thingName: '', selectedRoom: '', newRoom: '' }}
                  onSubmit={submitThingData}
                  validate={validate}
                >
                  {({ values, errors, touched, isSubmitting, handleSubmit, handleChange }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <DialogTitle>Gerät Einrichten</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          <strong> Typ:</strong> {openThing && extendedThingType(openThing)}
                          <br />
                          <strong> Adapter:</strong> {openThing?.adapter}
                          <br />
                          <strong>ID:</strong> {openThing?.adapterThingID}
                        </DialogContentText>
                        <Backdrop className={classes.backdrop} open={isSubmitting}>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                        <TextField
                          autoFocus
                          fullWidth
                          required
                          margin="normal"
                          name="thingName"
                          label="Name des Gerätes"
                          autoComplete="off"
                          value={values.thingName}
                          onChange={handleChange}
                          error={errors.thingName !== undefined && touched.thingName}
                          helperText={errors.thingName && touched.thingName && errors.thingName}
                        />
                        <FormControl
                          fullWidth
                          error={errors.selectedRoom !== undefined && touched.selectedRoom}
                        >
                          <InputLabel>Raum auswähnen</InputLabel>
                          <Select
                            value={values.selectedRoom}
                            name="selectedRoom"
                            onChange={handleChange}
                          >
                            {listThingsData.listThings
                              .reduce((roomList: string[], thing) => {
                                if (thing.room && roomList.indexOf(thing.room) === -1)
                                  roomList.push(thing.room);
                                return roomList;
                              }, [])
                              .map((room) => (
                                <MenuItem key={room} value={room}>
                                  {room}
                                </MenuItem>
                              ))}
                            <MenuItem value={selectNewRoom}>Neuern Raum erstellen</MenuItem>
                          </Select>
                          {errors.selectedRoom && touched.selectedRoom && (
                            <FormHelperText>{errors.selectedRoom}</FormHelperText>
                          )}
                        </FormControl>
                        {values.selectedRoom === selectNewRoom && (
                          <TextField
                            autoFocus
                            fullWidth
                            required
                            margin="normal"
                            name="newRoom"
                            label="Name des neuen Raumes"
                            autoComplete="off"
                            value={values.newRoom}
                            onChange={handleChange}
                            error={errors.newRoom !== undefined && touched.newRoom}
                            helperText={errors.newRoom && touched.newRoom && errors.newRoom}
                          />
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseThing} color="primary">
                          Abbruch
                        </Button>
                        <Button type="submit" color="primary">
                          Speichern
                        </Button>
                      </DialogActions>
                    </form>
                  )}
                </Formik>
              </Dialog>
            </>
          )}
      </Container>
    </>
  );
}
