import React from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Container,
  Typography,
  Divider,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import ThingCard from '../things/thing';
import { LIST_THING_QUERY, Thing, ListThingsData } from '../../api/things';
import * as Constants from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(4),
    },
    thingsGrid: {
      marginTop: 5,
    },
    thingsRoomName: {
      marginTop: 20,
    },
    thingsDivider: {
      marginTop: 20,
    },
    alertMargin: {
      marginTop: '5%',
    },
  })
);

const Home = () => {
  const classes = useStyles();

  const { loading, error, data } = useQuery<ListThingsData, undefined>(LIST_THING_QUERY, {
    // fetchPolicy: 'network-only',
  });
  if (error) console.log(JSON.stringify(error));
  if (data) console.log(data);

  return (
    <Container maxWidth="lg" className={classes.container}>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.alertMargin}>
          <CircularProgress />
        </div>
      )}
      {error && (
        <Alert
          severity="error"
          className={classes.alertMargin}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                console.log('refetching');
                window.location.reload(); // TODO only reload home component
              }}
            >
              Retry
            </Button>
          }
        >
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      )}
      {data && data.listThings.length === 0 && (
        <Alert
          severity="info"
          className={classes.alertMargin}
          action={
            <Button color="inherit" size="small" component={Link} to="/settings/create-thing">
              GERÄT HINZUFÜGEN
            </Button>
          }
        >
          Sie haben noch kein Gerät eingerichtet.
        </Alert>
      )}
      {data &&
        data.listThings.length > 0 &&
        Object.entries(
          data.listThings.reduce((roomMap: Record<string, Thing[]>, thing) => {
            (roomMap[thing.room || Constants.OtherDevices] =
              roomMap[thing.room || Constants.OtherDevices] || []).push(thing);
            return roomMap;
          }, {})
        )
          .sort(([roomName], [roomNameComp]) => {
            if (roomName === Constants.OtherDevices) return 1;
            if (roomNameComp === Constants.OtherDevices) return -1;
            return roomName.localeCompare(roomNameComp);
          })
          .map(([roomName, roomThings], idx, rooms) => (
            <div key={roomName}>
              <Typography
                variant="h5"
                component="h2"
                align="center"
                className={classes.thingsRoomName}
              >
                {roomName}
              </Typography>
              <Typography variant="subtitle1" align="center" color="textSecondary">
                {`${roomThings.length} ${roomThings.length === 1 ? 'Gerät' : 'Geräte'}`}
              </Typography>
              <Grid
                container
                spacing={3}
                className={classes.thingsGrid}
                alignItems="center"
                justify="center"
              >
                {roomThings.map((thing) => (
                  <Grid item key={thing.id}>
                    <ThingCard thing={thing} />
                  </Grid>
                ))}
              </Grid>
              {idx !== rooms.length - 1 && <Divider className={classes.thingsDivider} />}
            </div>
          ))}
    </Container>
  );
};

export default Home;
