import React, { useState, useEffect, useCallback } from 'react';
import {
  makeStyles,
  createStyles,
  Card,
  CardHeader,
  Switch,
  CardActions,
  Slider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import OpacityIcon from '@material-ui/icons/Opacity';
import { useMutation } from '@apollo/client';
import { ThingCardProps } from './thing';
import { PerformActionData, PERFORM_ACTION_MUTATION, PerformActionInput } from '../../api/action';
import { ThingTrait } from '../../api/things';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 100,
      minHeight: 110,
    },
    slider: {
      marginLeft: 15,
      marginRight: 15,
      width: 220,
    },
    cardHeader: {
      marginBottom: -20,
    },
  })
);

export default function ThingIrrigation({ thing }: ThingCardProps) {
  const classes = useStyles();

  const [performAction, { error }] = useMutation<PerformActionData, PerformActionInput>(
    PERFORM_ACTION_MUTATION
  );
  if (error) console.log(JSON.stringify(error));
  const { activatedAt, activatedFor } = thing.properties;

  const [timeLeftState, setTimeLeftState] = useState(0);
  const [timerDurationMinState, settimerDurationMinState] = useState(1);
  const calculateTimeLeft = () =>
    activatedAt + activatedFor * 60 > 0
      ? activatedAt + activatedFor * 60 - Math.floor(Date.now() / 1000)
      : 0;
  useEffect(() => {
    setTimeLeftState(
      activatedAt + activatedFor * 60 > 0
        ? activatedAt + activatedFor * 60 - Math.floor(Date.now() / 1000)
        : 0
    );
  }, [activatedAt, activatedFor]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeftState(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  if (
    !thing.availableActions.includes(ThingTrait.Timer) ||
    !thing.availableActions.includes(ThingTrait.Stop) ||
    typeof activatedAt !== 'number' ||
    !Number.isInteger(activatedAt) ||
    activatedAt < 0 ||
    typeof activatedFor !== 'number' ||
    !Number.isInteger(activatedFor) ||
    activatedFor < 0
  ) {
    console.log('invalid irrigation:', thing);
    return <></>;
  }

  async function handleTimerStop() {
    try {
      const res = await performAction({
        variables: {
          action: {
            thingId: thing.id,
            trait: ThingTrait.Stop,
            input: {},
          },
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleTimerStart() {
    try {
      const res = await performAction({
        variables: {
          action: {
            thingId: thing.id,
            trait: ThingTrait.Timer,
            input: {
              activatedAt: Math.floor(Date.now() / 1000),
              activatedFor: timerDurationMinState,
            },
          },
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  const handleTimerDurationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    settimerDurationMinState(event.target.value as number);
  };

  const getRemaningTimeStr = () => {
    if (timeLeftState < 0) return 'Error: Finished';
    let durStr = '';
    let metric = '';
    if (Math.floor((timeLeftState / (60 * 60)) % 24) > 0) {
      if (metric === '') metric = 'h';
      durStr += `${Math.floor((timeLeftState / (60 * 60)) % 24)
        .toString()
        .padStart(2, '0')}:`;
    }
    if (Math.floor(timeLeftState / 60) > 0) {
      if (metric === '') metric = 'min';
      durStr += `${Math.floor((timeLeftState / 60) % 60)
        .toString()
        .padStart(2, '0')}:`;
    }
    if (metric === '') metric = 'sek';
    durStr += Math.floor(timeLeftState % 60)
      .toString()
      .padStart(2, '0');
    return `${durStr} ${metric}`;
  };

  return (
    <Card className={classes.root}>
      <CardHeader avatar={<OpacityIcon />} className={classes.cardHeader} title={thing.name} />
      <CardActions>
        {timeLeftState > 0 ? (
          <>
            <p>{getRemaningTimeStr()}</p>
            <Button variant="outlined" color="primary" onClick={handleTimerStop}>
              Stop
            </Button>
          </>
        ) : (
          <>
            <FormControl fullWidth>
              <InputLabel>Dauer</InputLabel>
              <Select value={timerDurationMinState} onChange={handleTimerDurationChange}>
                {Array.from(Array(60), (_, i) => i + 1).map((i) => (
                  <MenuItem value={i} key={i}>
                    {i} min
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="outlined" color="primary" onClick={handleTimerStart}>
              Start
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
}
