import {
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  ConditionType,
  ConditionOperator,
  AutomationConditionServer,
  ConditionOperatorToString,
} from '../../api/automation';
import { Thing, ThingType, extendedThingType } from '../../api/things';

// ------------- Conditions
type AutomationConditionHelper = {
  type: ConditionType;
  device: {
    selectedThing?: Thing;
    deviceId?: string;
    operator?: ConditionOperator;
    propertyKey?: string;
    value?: any;
  };
};
function getDefaultConditionDialogInput(): AutomationConditionHelper {
  return {
    type: ConditionType.Device,
    device: {},
  };
}
function IsThingConditionSupported(thing: Thing) {
  switch (thing.type) {
    case ThingType.TemperatureSensor:
    case ThingType.HumiditySensor:
    case ThingType.SoilMoistureSensor:
      return true;
    default:
      return false;
  }
}
function GetConditionThingPropertyKeys(thing: Thing) {
  switch (thing.type) {
    case ThingType.TemperatureSensor:
      return ['temperature'];
    case ThingType.HumiditySensor:
      return ['humidity'];
    case ThingType.SoilMoistureSensor:
      return ['soilMoisture'];
    default:
      return [];
  }
}
export function ConditionPropertyKeyToString(propertyKey: string) {
  switch (propertyKey) {
    case 'temperature':
      return 'Temperatur';
    case 'humidity':
      return 'Luftfeuchtigkeit';
    case 'soilMoisture':
      return 'Bodenfeuchtigkeit';
    default:
      return 'error property key';
  }
}
export function ConditionPropertyKeyUnitStr(propertyKey: string) {
  switch (propertyKey) {
    case 'temperature':
      return '°C';
    case 'humidity':
    case 'soilMoisture':
      return '%';
    default:
      return 'error property key (unit)';
  }
}
function GetConditionThingOperator(thing: Thing, propertyKey: string) {
  switch (thing.type) {
    case ThingType.TemperatureSensor:
      if (propertyKey === 'temperature')
        return [ConditionOperator.Less, ConditionOperator.Equal, ConditionOperator.Greater];
      break;
    case ThingType.HumiditySensor:
      if (propertyKey === 'humidity')
        return [ConditionOperator.Less, ConditionOperator.Equal, ConditionOperator.Greater];
      break;
    case ThingType.SoilMoistureSensor:
      if (propertyKey === 'soilMoisture')
        return [ConditionOperator.Less, ConditionOperator.Equal, ConditionOperator.Greater];
      break;
    default:
  }
  return [];
}
function GetConditionThingValueItems(thing: Thing, propertyKey: string) {
  switch (thing.type) {
    case ThingType.TemperatureSensor:
      if (propertyKey === 'temperature')
        return Array.from(Array(51), (_, i) => i - 10).map((v) => (
          <MenuItem value={v} key={thing.id + propertyKey + v}>
            {v}°C
          </MenuItem>
        ));
      break;
    case ThingType.HumiditySensor:
      if (propertyKey === 'humidity')
        return Array.from(Array(101).keys()).map((v) => (
          <MenuItem value={v} key={thing.id + propertyKey + v}>
            {v}%
          </MenuItem>
        ));
      break;
    case ThingType.SoilMoistureSensor:
      if (propertyKey === 'soilMoisture')
        return Array.from(Array(101).keys()).map((v) => (
          <MenuItem value={v} key={thing.id + propertyKey + v}>
            {v}%
          </MenuItem>
        ));
      break;
    default:
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(4),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    headingAccordion: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
    },
    cardAutomation: {
      marginTop: 16,
    },
    dialogSelectMargin: {
      marginTop: 10,
    },
    loadingIndicator: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '5%',
    },
  })
);

type ConditionHelperProps = {
  dialogOpen: boolean;
  handleCloseDialog: () => void;
  addCondition: (condition: AutomationConditionServer) => void;
  availableThings: Thing[];
  handleError: () => void;
};

export default function ConditionHelper({
  dialogOpen,
  handleCloseDialog,
  addCondition,
  availableThings,
  handleError,
}: ConditionHelperProps) {
  const classes = useStyles();

  const getThingById = (id: string) => {
    return availableThings.find((thing) => thing.id === id);
  };
  const [conditionDialogInputState, setConditionDialogInputState] = useState<
    AutomationConditionHelper
  >(getDefaultConditionDialogInput);
  const conditionFail = () => {
    setConditionDialogInputState(getDefaultConditionDialogInput);
    handleError();
  };
  const handleConditionTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setConditionDialogInputState({
      ...conditionDialogInputState,
      type: event.target.value as ConditionType,
    });
  };
  const handleConditionDeviceDeviceIDChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedThing = getThingById(event.target.value as string);
    if (selectedThing === undefined) conditionFail();
    else
      setConditionDialogInputState({
        ...conditionDialogInputState,
        device: {
          selectedThing,
          deviceId: selectedThing.id,
        },
      });
  };
  const handleConditionDevicePropertyKeyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setConditionDialogInputState({
      ...conditionDialogInputState,
      device: {
        selectedThing: conditionDialogInputState.device.selectedThing,
        deviceId: conditionDialogInputState.device.deviceId,
        propertyKey: event.target.value as string,
      },
    });
  };
  const handleConditionDeviceOperatorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setConditionDialogInputState({
      ...conditionDialogInputState,
      device: {
        ...conditionDialogInputState.device,
        operator: event.target.value as ConditionOperator,
      },
    });
  };
  const handleConditionDeviceValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setConditionDialogInputState({
      ...conditionDialogInputState,
      device: {
        ...conditionDialogInputState.device,
        value: event.target.value as any,
      },
    });
  };

  const handleAddConditionClicked = () => {
    if (!conditionDialogInputState.type) return conditionFail();
    let condition: AutomationConditionServer;
    switch (conditionDialogInputState.type) {
      case ConditionType.Device:
        if (
          !conditionDialogInputState.device.deviceId ||
          !conditionDialogInputState.device.selectedThing ||
          !conditionDialogInputState.device.propertyKey ||
          !conditionDialogInputState.device.operator ||
          !conditionDialogInputState.device.value
        )
          return conditionFail();
        condition = {
          type: conditionDialogInputState.type,
          device: {
            deviceId: conditionDialogInputState.device.deviceId,
            operator: conditionDialogInputState.device.operator,
            propertyKey: conditionDialogInputState.device.propertyKey,
            value: conditionDialogInputState.device.value,
          },
        };
        break;
      default:
        return conditionFail();
    }
    setConditionDialogInputState(getDefaultConditionDialogInput);
    addCondition(condition);
  };

  return (
    <Dialog open={dialogOpen} onClose={handleCloseDialog}>
      <DialogTitle>Bedingung hinzufügen</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Typ</InputLabel>
          <Select value={conditionDialogInputState.type} onChange={handleConditionTypeChange}>
            {/* <MenuItem value={ConditionType.Time}>Zeit</MenuItem> */}
            <MenuItem value={ConditionType.Device}>Gerät</MenuItem>
            {/* <MenuItem value={ConditionType.Sun}>Sonne</MenuItem> */}
          </Select>
        </FormControl>
        {conditionDialogInputState.type === ConditionType.Device && (
          <>
            <FormControl fullWidth className={classes.dialogSelectMargin}>
              <InputLabel>Gerät</InputLabel>
              <Select
                value={conditionDialogInputState.device.deviceId ?? ''}
                onChange={handleConditionDeviceDeviceIDChange}
              >
                {availableThings
                  .filter((thing) => IsThingConditionSupported(thing))
                  .map((thing) => {
                    return (
                      <MenuItem value={thing.id} key={thing.id}>
                        {`${thing.name} (${extendedThingType(thing)})`}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {conditionDialogInputState.device.selectedThing && (
              <>
                <FormControl fullWidth className={classes.dialogSelectMargin}>
                  <InputLabel>Eigenschaft</InputLabel>
                  <Select
                    value={conditionDialogInputState.device.propertyKey ?? ''}
                    onChange={handleConditionDevicePropertyKeyChange}
                  >
                    {GetConditionThingPropertyKeys(
                      conditionDialogInputState.device.selectedThing
                    ).map((propertyKey) => (
                      <MenuItem
                        value={propertyKey}
                        key={conditionDialogInputState.device.selectedThing + propertyKey}
                      >
                        {ConditionPropertyKeyToString(propertyKey)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {conditionDialogInputState.device.propertyKey && (
                  <>
                    <FormControl fullWidth className={classes.dialogSelectMargin}>
                      <InputLabel>Operator</InputLabel>
                      <Select
                        value={conditionDialogInputState.device.operator ?? ''}
                        onChange={handleConditionDeviceOperatorChange}
                      >
                        {GetConditionThingOperator(
                          conditionDialogInputState.device.selectedThing,
                          conditionDialogInputState.device.propertyKey
                        ).map((operator) => {
                          if (
                            conditionDialogInputState.device.selectedThing &&
                            conditionDialogInputState.device.propertyKey
                          )
                            return (
                              <MenuItem
                                value={operator}
                                key={
                                  conditionDialogInputState.device.selectedThing +
                                  conditionDialogInputState.device.propertyKey +
                                  operator
                                }
                              >
                                {ConditionOperatorToString(operator)}
                              </MenuItem>
                            );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth className={classes.dialogSelectMargin}>
                      <InputLabel>Wert</InputLabel>
                      <Select
                        value={conditionDialogInputState.device.value ?? ''}
                        onChange={handleConditionDeviceValueChange}
                      >
                        {GetConditionThingValueItems(
                          conditionDialogInputState.device.selectedThing,
                          conditionDialogInputState.device.propertyKey
                        )}
                      </Select>
                    </FormControl>
                  </>
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Abbruch
        </Button>
        <Button onClick={handleAddConditionClicked} color="primary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
