import axios from 'axios';
import * as Constants from '../utils/constants';

type UserCerdentials = {
  email: string;
  password: string;
};

export type SignInData = UserCerdentials;
export type SignUpData = UserCerdentials & { bridgeId: string };

export type AuthApiUser = {
  email: string;
};

export const isNetworkError = (err: any): boolean =>
  err.isAxiosError === true && err.response === undefined;

const axiosInstance = axios.create({
  timeout: 5000,
});

const signIn = async (data: UserCerdentials) => {
  console.log('AuthApi: SignIn');
  const resp = await axiosInstance.post<string>(Constants.SignInUrl, data, {
    withCredentials: true,
  });
  console.log('AuthApi resp:', resp);
  return resp;
};

const signUp = async (data: UserCerdentials) => {
  console.log('AuthApi: SignUp');
  const resp = await axiosInstance.post<string>(Constants.SignUpUrl, data, {
    withCredentials: true,
  });
  console.log('AuthApi resp:', resp);
  return resp;
};

const logout = async () => {
  console.log('AuthApi: Logout');
  const resp = await axiosInstance.post<string>(Constants.LogoutUrl, undefined, {
    withCredentials: true,
  });
  console.log('AuthApi resp:', resp);
  return resp;
};

const authApi = {
  signIn,
  signUp,
  logout,
};
export default authApi;
