import React, { ReactNode } from 'react';
import Cookies from 'js-cookie';
import authApi, { AuthApiUser, SignInData, SignUpData } from '../api/auth';
import * as Constants from '../utils/constants';
import createCtx from '../utils/createContext';

type AuthProviderProps = {
  children: ReactNode;
};
type AuthContextState =
  | { isAuthenticated: false; user?: undefined }
  | {
      isAuthenticated: true;
      user: AuthApiUser;
    };
type AuthContextActions = {
  signIn: (data: SignInData) => Promise<string>;
  signUp: (data: SignUpData) => Promise<string>;
  logout: () => Promise<string>;
};

function parseJwt(token: string): AuthApiUser | undefined {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  const data = JSON.parse(jsonPayload);
  console.log('JWT data', data);
  return data;
}

function readUserCookie(): AuthApiUser | undefined {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
    return { email: 'test@localhost.de' };
  const jwt = Cookies.get(Constants.JWTCookieKey);
  return jwt ? parseJwt(jwt) : undefined;
}

function getAuthContextDefaultState(): AuthContextState {
  const user = readUserCookie();
  return user ? { isAuthenticated: true, user } : { isAuthenticated: false };
}

export const [AuthContext, useAuthCtx] = createCtx<AuthContextState & AuthContextActions>();

export function AuthProvider({ children }: AuthProviderProps) {
  const [authState, setAuthState] = React.useState<AuthContextState>(getAuthContextDefaultState);

  const signIn = async (input: SignInData) => {
    let msg = '';
    try {
      const res = await authApi.signIn(input);
      console.log('Auth success: ', res);
      const user = readUserCookie();
      if (!user) {
        msg = 'Error reading user cookie';
      } else {
        setAuthState({ isAuthenticated: true, user });
      }
    } catch (err) {
      console.dir(err);
      msg = err.response?.data ?? Constants.DefaultError;
    }
    return msg;
  };

  const signUp = async (input: SignUpData) => {
    let msg = '';
    try {
      const res = await authApi.signUp(input);
      console.log('Auth success: ', res);
      const user = readUserCookie();
      if (!user) {
        msg = 'Error reading user cookie';
      } else {
        setAuthState({ isAuthenticated: true, user });
      }
    } catch (err) {
      console.dir(err);
      msg = err.response?.data ?? Constants.DefaultError;
    }
    return msg;
  };

  const logout = async () => {
    let msg = '';
    try {
      const res = await authApi.logout();
      console.log('Auth success: ', res);
      setAuthState({ isAuthenticated: false });
    } catch (err) {
      console.dir(err);
      msg = err.response?.data ?? Constants.DefaultError;
    }
    return msg;
  };

  return (
    <AuthContext.Provider value={{ ...authState, signIn, signUp, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
