import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import CreateThing from './CreateThing';
import RoomSettings from './RoomSettings';
import ThingSettings from './ThingSettings';

export default function SettingsLayout() {
  return (
    <Switch>
      <Route path="/settings/create-thing" component={CreateThing} />
      <Route path="/settings/room-settings/:roomName" component={RoomSettings} />
      <Route path="/settings/thing-settings/:thingID" component={ThingSettings} />
      <Redirect from="*" to="/app/settings" />
    </Switch>
  );
}
