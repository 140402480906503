import React from 'react';
import { Link } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, makeStyles } from '@material-ui/core';
import { PathMap, PathToIndex } from './HomeLayout';

const useStyles = makeStyles({
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  fixPadding: {
    width: '100%',
    position: 'sticky',
    bottom: 0,
    height: 56,
  },
});

type bottomNavProp = { path: string };
const BottomNav = ({ path }: bottomNavProp) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.fixPadding} />
      <BottomNavigation value={PathToIndex(path)} showLabels className={classes.stickToBottom}>
        {PathMap.map((page) => (
          <BottomNavigationAction
            label={page.title}
            icon={page.bottomIcon}
            component={Link}
            to={page.path}
            key={page.path}
          />
        ))}
      </BottomNavigation>
    </>
  );
};

export default BottomNav;
