import { TextField } from '@material-ui/core';
import React from 'react';
import { StepperObjectContentProps } from './GeneralSetup';

export type HouseLocation = {
  latitude: number;
  longitude: number;
};

export default function SetLocation({
  values,
  errors,
  handleChange,
}: StepperObjectContentProps<HouseLocation>) {
  return (
    <>
      <TextField
        autoFocus
        fullWidth
        required
        type="number"
        margin="normal"
        variant="outlined"
        name="latitude"
        label="Latitude"
        value={Number.isNaN(values.latitude) ? '' : values.latitude}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChange({ ...values, latitude: parseFloat(event.target.value) })
        }
        error={errors.latitude !== undefined}
        helperText={errors.latitude}
      />
      <TextField
        autoFocus
        fullWidth
        required
        type="number"
        margin="normal"
        variant="outlined"
        name="longitude"
        label="Longitude"
        value={Number.isNaN(values.longitude) ? '' : values.longitude}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChange({ ...values, longitude: parseFloat(event.target.value) })
        }
        error={errors.longitude !== undefined}
        helperText={errors.longitude}
      />
    </>
  );
}
