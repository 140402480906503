import { gql, OnSubscriptionDataOptions, useSubscription } from '@apollo/client';
import React from 'react';
import {
  ThingChangeData,
  ThingChangeType,
  THING_ATTRIBUTES,
  THING_CHANGE_SUBSCRIPTION,
} from './things';

export default function ThingSubscriber() {
  const onThingChange = ({
    client,
    subscriptionData,
  }: OnSubscriptionDataOptions<ThingChangeData>) => {
    const { data, error, loading } = subscriptionData;
    console.log('Sub cb: ', subscriptionData);
    if (loading) console.log('Sub cb loading: ', loading);
    else if (error) console.log('Sub cb error: ', error);
    else if (data) {
      const change = data.thingChange;

      switch (change.type) {
        case ThingChangeType.ThingAllSync: {
          if (!change.syncAll) {
            console.log('Thing added Error - syncAll null');
            break;
          }
          console.log('Thing sync: ', change.syncAll);
          const syncedThingRefs: any[] = [];
          change.syncAll.forEach((thing) => {
            syncedThingRefs.push(
              client.cache.writeFragment({
                data: thing,
                fragment: THING_ATTRIBUTES,
              })
            );
          });
          client.cache.modify({
            fields: {
              listThings() {
                return syncedThingRefs;
              },
            },
          });
          break;
        }
        case ThingChangeType.ThingAdded: {
          if (!change.newThing) {
            console.log('Thing added Error - new thing null');
            break;
          }
          console.log('Thing added: ', change.newThing);
          const newThingRef = client.cache.writeFragment({
            data: change.newThing,
            fragment: THING_ATTRIBUTES,
          });
          client.cache.modify({
            fields: {
              listThings(existingThingRefs = [], { readField }) {
                if (
                  existingThingRefs.some((ref: any) => readField('id', ref) === change.newThing?.id)
                )
                  return existingThingRefs;
                return [...existingThingRefs, newThingRef];
              },
            },
          });
          break;
        }
        case ThingChangeType.ThingRemoved: {
          console.log('Thing removed: ', change.thingId);
          client.cache.modify({
            fields: {
              listThings(existingThingRefs = [], { readField }) {
                return existingThingRefs.filter(
                  (thingRef: any) => change.thingId !== readField('id', thingRef)
                );
              },
            },
          });
          break;
        }
        case ThingChangeType.ThingPropertyChange: {
          if (!change.updatedProperties) {
            console.log('ThingPropertyChange Error - updated properties null');
            return;
          }
          console.log('ThingPropertyChange: ', change.updatedProperties);
          client.cache.writeFragment({
            id: `Thing:${change.thingId}`,
            data: { properties: change.updatedProperties },
            fragment: gql`
              fragment thingProperties on Thing {
                properties
              }
            `,
          });
          break;
        }
        case ThingChangeType.ThingRename: {
          if (typeof change.updatedName !== 'string') {
            console.log('ThingRename Error - updated name null');
            return;
          }
          console.log('ThingRename: ', change.updatedName);
          client.cache.writeFragment({
            id: `Thing:${change.thingId}`,
            data: { name: change.updatedName },
            fragment: gql`
              fragment thingName on Thing {
                name
              }
            `,
          });
          break;
        }
        case ThingChangeType.ThingRoomChange: {
          if (typeof change.updatedRoom !== 'string') {
            console.log('ThingRoomChange Error - updated room null');
            return;
          }
          console.log('ThingRoomChange: ', change.updatedRoom);
          client.cache.writeFragment({
            id: `Thing:${change.thingId}`,
            data: { room: change.updatedRoom },
            fragment: gql`
              fragment thingRoom on Thing {
                room
              }
            `,
          });
          break;
        }
        default:
          console.log('Sub type not implemented yet: ', change.type);
      }
    } else console.log('Sub cb data nil!!');
  };

  const onSubComplete = () => {
    console.log('Sub complete!!!!!!!!!!!!!');
  };

  const { loading, error } = useSubscription<ThingChangeData, undefined>(
    THING_CHANGE_SUBSCRIPTION,
    {
      onSubscriptionData: onThingChange,
      onSubscriptionComplete: onSubComplete,
    }
  );

  if (error) console.log('Sub Error: ', error);
  if (loading) console.log('Sub loading: ', loading);
  // if (data) console.log('Sub data: ', data);
  return <></>;
}
