import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: '10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  acceptFormControlLabel: {
    fontSize: '14px',
  },
  copyrightLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  copyrightBox: {
    marginBottom: '20px',
  },
  forgotPassword: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  signInUpLink: {
    color: theme.palette.primary.main,
    alignSelf: 'flex-end',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    // color: '#fff',
  },
}));

export default useStyles;
