import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from '@material-ui/core';
import React, { useState } from 'react';
import { AutomationActionServer } from '../../api/automation';
import { ThingTrait, extendedThingType, ThingTraitToString, Thing } from '../../api/things';

type AutomationActionHelper = {
  deviceId?: string;
  trait?: ThingTrait;
  input: Record<string, any>;
};

function getDefaultActionDialogInput(): AutomationActionHelper {
  return {
    input: {},
  };
}

function IsTraitImplemented(trait: ThingTrait) {
  switch (trait) {
    case ThingTrait.OnOff:
    case ThingTrait.OpenClose:
    case ThingTrait.Brightness:
    case ThingTrait.FanSpeed:
      return true;
    default:
      return false;
  }
}
function IsAnyTraitImplemented(traits: ThingTrait[]) {
  return traits.some((trait) => IsTraitImplemented(trait));
}

type ActionHelperProps = {
  dialogOpen: boolean;
  handleCloseDialog: () => void;
  addAction: (action: AutomationActionServer) => void;
  availableThings: Thing[];
  handleError: () => void;
};

export default function ActionHelper({
  dialogOpen,
  handleCloseDialog,
  addAction,
  availableThings,
  handleError,
}: ActionHelperProps) {
  const getThingById = (id: string) => {
    return availableThings.find((thing) => thing.id === id);
  };
  const [actionDialogInputState, setactionDeviceInputState] = useState<AutomationActionHelper>(
    getDefaultActionDialogInput
  );
  const [selectedThing, setselectedThing] = useState<Thing>();
  const actionFail = () => {
    setactionDeviceInputState(getDefaultActionDialogInput);
    handleError();
  };
  const handleActionDeviceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedThingTmp = getThingById(event.target.value as string);
    if (selectedThingTmp === undefined) actionFail();
    else {
      setactionDeviceInputState({
        ...getDefaultActionDialogInput(),
        deviceId: event.target.value as string,
      });
      setselectedThing(selectedThingTmp);
    }
  };
  const handleActionTraitChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setactionDeviceInputState({
      ...actionDialogInputState,
      trait: event.target.value as ThingTrait,
    });
  };
  const handleActionCmdChange = (key: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
    let updatedValue = event.target.value;
    if (updatedValue === 'true' || updatedValue === 'false')
      updatedValue = JSON.parse(updatedValue);
    setactionDeviceInputState({
      ...actionDialogInputState,
      input: { ...actionDialogInputState.input, [key]: updatedValue },
    });
  };

  const handleAddActionClicked = () => {
    if (actionDialogInputState.deviceId === undefined || actionDialogInputState.trait === undefined)
      return actionFail();
    switch (actionDialogInputState.trait) {
      case ThingTrait.OnOff:
        if (typeof actionDialogInputState.input.on !== typeof true) return actionFail();
        break;
      case ThingTrait.Brightness:
        if (
          typeof actionDialogInputState.input.brightness !== 'number' ||
          !Number.isInteger(actionDialogInputState.input.brightness) ||
          actionDialogInputState.input.brightness <= 0 ||
          actionDialogInputState.input.brightness > 100
        )
          return actionFail();
        break;
      case ThingTrait.OpenClose:
        if (typeof actionDialogInputState.input.open !== typeof true) return actionFail();
        break;
      case ThingTrait.FanSpeed:
        if (
          typeof selectedThing?.properties?.maxFanSpeed !== 'number' ||
          !Number.isInteger(selectedThing?.properties?.maxFanSpeed) ||
          actionDialogInputState.input.fanSpeed <= 0 ||
          actionDialogInputState.input.fanSpeed > selectedThing?.properties?.maxFanSpeed
        )
          return actionFail();
        break;
      default:
        return actionFail();
    }
    setactionDeviceInputState(getDefaultActionDialogInput);
    addAction({
      deviceId: actionDialogInputState.deviceId,
      trait: actionDialogInputState.trait,
      input: actionDialogInputState.input,
    });
  };

  return (
    <Dialog open={dialogOpen} onClose={handleCloseDialog}>
      <DialogTitle>Aktion hinzufügen</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Gerät</InputLabel>
          <Select value={actionDialogInputState.deviceId ?? ''} onChange={handleActionDeviceChange}>
            {availableThings
              .filter((thing) => IsAnyTraitImplemented(thing.availableActions))
              .map((thing) => {
                return (
                  <MenuItem value={thing.id} key={thing.id}>
                    {`${thing.name} (${extendedThingType(thing)})`}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        {actionDialogInputState.deviceId && (
          <FormControl fullWidth>
            <InputLabel>Verfügbare Aktionen</InputLabel>
            <Select value={actionDialogInputState.trait ?? ''} onChange={handleActionTraitChange}>
              {getThingById(actionDialogInputState.deviceId)
                ?.availableActions.filter((trait) => IsTraitImplemented(trait))
                .map((trait) => {
                  return (
                    <MenuItem value={trait} key={trait}>
                      {ThingTraitToString(trait)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
        {actionDialogInputState.trait === ThingTrait.OnOff && (
          <FormControl fullWidth>
            <InputLabel>Befehl</InputLabel>
            <Select
              value={actionDialogInputState.input?.on ?? ''}
              onChange={handleActionCmdChange('on')}
            >
              <MenuItem value="true">An</MenuItem>
              <MenuItem value="false">Aus</MenuItem>
            </Select>
          </FormControl>
        )}
        {actionDialogInputState.trait === ThingTrait.Brightness && (
          <FormControl fullWidth>
            <InputLabel>Befehl</InputLabel>
            <Select
              value={actionDialogInputState.input?.brightness ?? ''}
              onChange={handleActionCmdChange('brightness')}
            >
              {Array.from(Array(100), (_, i) => i + 1).map((i) => (
                <MenuItem value={i} key={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {actionDialogInputState.trait === ThingTrait.OpenClose && (
          <>
            <FormControl fullWidth>
              <InputLabel>Befehl</InputLabel>
              <Select
                value={actionDialogInputState.input?.open ?? ''}
                onChange={handleActionCmdChange('open')}
              >
                <MenuItem value="true">Auf</MenuItem>
                <MenuItem value="false">Zu</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Dauer</InputLabel>
              <Select
                value={actionDialogInputState.input?.duration ?? 0}
                onChange={handleActionCmdChange('duration')}
              >
                <MenuItem value={0}>Kein Limit</MenuItem>
                {Array.from(Array(40), (_, i) => (i + 1) * 5).map((i) => (
                  <MenuItem value={i} key={i}>
                    {i} sec
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        {actionDialogInputState.trait === ThingTrait.FanSpeed &&
          typeof selectedThing?.properties?.maxFanSpeed === 'number' &&
          Number.isInteger(selectedThing?.properties?.maxFanSpeed) && (
            <FormControl fullWidth>
              <InputLabel>Befehl</InputLabel>
              <Select
                value={actionDialogInputState.input?.fanSpeed ?? ''}
                onChange={handleActionCmdChange('fanSpeed')}
              >
                {Array.from(Array(selectedThing?.properties?.maxFanSpeed), (_, i) => i + 1).map(
                  (i) => (
                    <MenuItem value={i} key={i}>
                      Stufe {i}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Abbruch
        </Button>
        <Button onClick={handleAddActionClicked} color="primary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
