import React from 'react';
import {
  makeStyles,
  createStyles,
  Card,
  CardHeader,
  CardActions,
  ButtonGroup,
  Button,
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import StopIcon from '@material-ui/icons/Stop';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import { useMutation } from '@apollo/client';
import { ThingCardProps } from './thing';
import { PerformActionData, PerformActionInput, PERFORM_ACTION_MUTATION } from '../../api/action';
import { ThingTrait } from '../../api/things';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 100,
      minHeight: 110,
    },
    cardHeader: {
      marginBottom: -20,
    },
    shutterActions: {
      marginTop: 10,
      marginRight: 0,
    },
  })
);

export default function ThingShutter({ thing }: ThingCardProps) {
  const classes = useStyles();

  const [performAction, { error }] = useMutation<PerformActionData, PerformActionInput>(
    PERFORM_ACTION_MUTATION
  );
  if (error) console.log(JSON.stringify(error));

  async function handleStopButton() {
    try {
      const res = await performAction({
        variables: {
          action: {
            thingId: thing.id,
            trait: ThingTrait.Stop,
            input: {},
          },
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleDownButton() {
    try {
      const res = await performAction({
        variables: {
          action: {
            thingId: thing.id,
            trait: ThingTrait.OpenClose,
            input: {
              open: false,
            },
          },
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleUpButton() {
    try {
      const res = await performAction({
        variables: {
          action: {
            thingId: thing.id,
            trait: ThingTrait.OpenClose,
            input: {
              open: true,
            },
          },
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  const shutterState = thing.properties.open;
  if (!thing.availableActions.includes(ThingTrait.OpenClose) || typeof shutterState !== 'boolean') {
    console.log('invalid shutter:', thing);
    return <></>;
  }

  return (
    <Card className={classes.root}>
      <CardHeader avatar={<ViewHeadlineIcon />} className={classes.cardHeader} title={thing.name} />
      <CardActions>
        <ButtonGroup color="primary" className={classes.shutterActions}>
          <Button color="primary" onClick={handleUpButton}>
            <ArrowDropUpIcon />
          </Button>
          {thing.availableActions.includes(ThingTrait.Stop) && (
            <Button color="primary" onClick={handleStopButton}>
              <StopIcon />
            </Button>
          )}
          <Button color="primary" onClick={handleDownButton}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
}
