import React from 'react';
import { Button, Container, makeStyles, createStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => createStyles({ marginTop: { marginTop: '1%' } }));

const Policies = () => {
  const classes = useStyles();
  return (
    <Container className={classes.marginTop} maxWidth="md">
      <h1>Policies</h1>
      <Button variant="contained" color="secondary" component={Link} to="/policies/copyright">
        Copyright
      </Button>
      <br />
      <Button variant="contained" color="secondary" className={classes.marginTop}>
        Privacy Policy
      </Button>
      <br />
      <Button variant="contained" color="secondary" className={classes.marginTop}>
        Terms of Service
      </Button>
    </Container>
  );
};
export default Policies;
