export const AppName = 'Homeapp';
export const FullAppName = 'Homeapp Gessler';
export const AppDomain = 'home-gessler.de';

export const GQLBackendHTTP = `${process.env.REACT_APP_GRAPHQL_URL}`;
export const GQLBackendWS = `${process.env.REACT_APP_GRAPHQL_URL_WS}`;

export const AuthBackend = `${process.env.REACT_APP_AUTH_URL}`;
export const SignInUrl = `${AuthBackend}/signin`;
export const SignUpUrl = `${AuthBackend}/signup`;
export const LogoutUrl = `${AuthBackend}/logout`;
// export const CurrentUserUrl = `${AuthBackend}/current-user`;

export const OAuth2Backend = `${process.env.REACT_APP_OAUTH2_URL}`;
export const OAuthAuthorizeUrl = `${OAuth2Backend}/authorize`;

export const EmailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$';
export const HouseNameRegex = '^[a-zA-Z0-9]+$';

export const LetterNumberSpaceRegex = /^[a-zA-Z0-9 äöüÄÖÜß]+$/;

export const DefaultError =
  'Es ist ein Fehler aufgetreten! Bitte laden sie die Setie neu und versuchen es nochmal.';
export const NetworkError =
  'Netzwerk Fehler! Bitte laden sie die Setie neu und versuchen es nochmal.';

export const OtherDevices = 'Sonstige Geräte';

export const JWTCookieKey = 'jwt';
