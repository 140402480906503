import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import {
  Container,
  CircularProgress,
  Toolbar,
  IconButton,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikErrors } from 'formik';
import {
  ListThingsData,
  LIST_THING_QUERY,
  DELETE_THING_MUTATION,
  DeleteThingData,
  DeleteThingInput,
  RenameThingData,
  RenameThingInput,
  RENAME_THING_MUTATION,
  UpdateThingRoomData,
  UpdateThingRoomInput,
  UPDATE_THING_ROOM_MUTATION,
  extendedThingType,
} from '../../api/things';
import * as Constants from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertMargin: {
      marginTop: '5%',
    },
    container: {
      paddingBottom: theme.spacing(4),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    deviceInfo: {
      marginTop: 10,
    },
  })
);

type RenameThingUserInput = {
  name: string;
};

const selectNewRoom = '#newRoom';
type UpdateThingRoomUserInput = {
  selectedRoom: string;
  newRoom: string;
};

export default function ThingSettings() {
  const classes = useStyles();

  const { thingID }: { thingID?: string } = useParams();
  console.log('Thing Settings for Thing:', thingID);

  const { loading, error, data, refetch, networkStatus } = useQuery<ListThingsData, undefined>(
    LIST_THING_QUERY
  );
  if (error) console.log(JSON.stringify(error));
  if (data) console.log(data);

  const thing = data?.listThings.find((item) => item.id === thingID);

  const history = useHistory();
  const historyGoSettingsOrRoom = () => {
    if (thing) history.push(`/settings/room-settings/${thing.room}`);
    else history.push('/app/settings');
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  function handleCloseDeleteDialog() {
    setDeleteDialogOpen(false);
  }
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  function handleCloseRenameDialog() {
    setRenameDialogOpen(false);
  }
  const [updateThingDialogOpen, setUpdateThingDialogOpen] = React.useState(false);
  function handleCloseUpdateThingDialog() {
    setUpdateThingDialogOpen(false);
  }

  const [refetchTriggered, setRefetchTriggered] = React.useState(false);

  const [deleteThing, { error: deleteThingError, loading: deleteThingLoading }] = useMutation<
    DeleteThingData,
    DeleteThingInput
  >(DELETE_THING_MUTATION, {
    update(cache, { data: res }) {
      const delThing = res?.deleteThing;
      if (!delThing) return;
      cache.modify({
        fields: {
          listThings(existingThingRefs = [], { readField }) {
            return existingThingRefs.filter(
              (thingRef: any) => delThing.id !== readField('id', thingRef)
            );
          },
        },
      });
    },
  });

  const deleteClicked = async () => {
    if (thingID === undefined) return;
    try {
      setRefetchTriggered(false);
      await deleteThing({
        variables: {
          id: thingID,
        },
      });
      historyGoSettingsOrRoom();
    } catch (err) {
      console.log(err);
      handleCloseDeleteDialog();
    }
  };

  const [renameThing, { error: renameThingError, loading: renameThingLoading }] = useMutation<
    RenameThingData,
    RenameThingInput
  >(RENAME_THING_MUTATION);

  const renameValidate = (input: RenameThingUserInput) => {
    const errors: FormikErrors<RenameThingUserInput> = {};
    if (input.name === '') errors.name = 'Neuen Gerätename eingeben.';
    else if (input.name.length > 50) errors.name = 'Maximal 50 Zeichen.';
    else if (!Constants.LetterNumberSpaceRegex.test(input.name))
      errors.name = 'Nur Buchstaben, Zahlen und Leerzeichen zulässig.';
    return errors;
  };

  const renameClicked = async (input: RenameThingUserInput) => {
    if (thingID === undefined) return;
    try {
      setRefetchTriggered(false);
      await renameThing({
        variables: {
          id: thingID,
          name: input.name,
        },
      });
    } catch (err) {
      console.log(err);
    }
    handleCloseRenameDialog();
  };

  const [updateRoomThing, { error: updateRoomError, loading: updateRoomLoading }] = useMutation<
    UpdateThingRoomData,
    UpdateThingRoomInput
  >(UPDATE_THING_ROOM_MUTATION);

  const updateRoomValidate = (input: UpdateThingRoomUserInput) => {
    const errors: FormikErrors<UpdateThingRoomUserInput> = {};
    if (input.selectedRoom === selectNewRoom) {
      if (input.newRoom === '') errors.newRoom = 'Neuen Raumnamen Eingeben.';
      else if (!Constants.LetterNumberSpaceRegex.test(input.newRoom))
        errors.newRoom = 'Nur Buchstaben, Zahlen und Leerzeichen zulässig.';
    } else if (!Constants.LetterNumberSpaceRegex.test(input.selectedRoom))
      errors.selectedRoom = 'Unzulässiger Raumname';
    return errors;
  };

  const updateThingRoomClicked = async (input: UpdateThingRoomUserInput) => {
    if (thingID === undefined) return;
    try {
      setRefetchTriggered(false);
      await updateRoomThing({
        variables: {
          id: thingID,
          roomName: input.selectedRoom !== selectNewRoom ? input.selectedRoom : input.newRoom,
        },
      });
    } catch (err) {
      console.log(err);
    }
    handleCloseUpdateThingDialog();
  };

  async function triggerRefetch() {
    console.log('refetching scan data');
    try {
      setRefetchTriggered(true);
      await refetch();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={historyGoSettingsOrRoom}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Geräteeinstellungen
        </Typography>
      </Toolbar>
      <Container maxWidth="lg" className={classes.container}>
        {(loading || networkStatus === NetworkStatus.refetch) && (
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={classes.alertMargin}
          >
            <CircularProgress />
          </div>
        )}
        <Backdrop
          className={classes.backdrop}
          open={deleteThingLoading || renameThingLoading || updateRoomLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {(error ||
          ((deleteThingError || renameThingError || updateRoomError) && !refetchTriggered)) && (
          <Alert
            severity="error"
            className={classes.alertMargin}
            action={
              <Button color="inherit" size="small" onClick={triggerRefetch}>
                Retry
              </Button>
            }
          >
            <AlertTitle>Error</AlertTitle>
            {error?.message ??
              deleteThingError?.message ??
              renameThingError?.message ??
              updateRoomError?.message}
          </Alert>
        )}
        {data && thing && networkStatus === NetworkStatus.ready && (
          <>
            <List
              subheader={
                <ListSubheader color="primary" disableSticky>
                  Allgemein
                </ListSubheader>
              }
            >
              <ListItem button onClick={() => setRenameDialogOpen(true)}>
                <ListItemText primary="Name" secondary={thing.name} />
              </ListItem>
              <ListItem button onClick={() => setUpdateThingDialogOpen(true)}>
                <ListItemText
                  primary="Raum"
                  secondary={thing.room || 'Gerät einem Raum hinzufügen'}
                />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem button onClick={() => setDeleteDialogOpen(true)}>
                <ListItemText primary="Gerät entfernen" />
              </ListItem>
            </List>
            <Divider />
            <Typography
              component="p"
              variant="body1"
              color="textSecondary"
              className={classes.deviceInfo}
            >
              <strong> Typ:</strong> {extendedThingType(thing)}
              <br />
              <strong> Adapter:</strong> {thing.adapter}
              <br />
              <strong>ID:</strong> {thing.adapterThingID}
            </Typography>
            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Gerät {thing.name} wirklich löschen</DialogTitle>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">
                  Abbruch
                </Button>
                <Button onClick={deleteClicked} color="primary">
                  Löschen
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={renameDialogOpen} onClose={handleCloseRenameDialog}>
              <Formik
                initialValues={{ name: thing.name }}
                onSubmit={renameClicked}
                validate={renameValidate}
              >
                {({ values, errors, touched, handleSubmit, handleChange }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <DialogTitle>Gerät umbenennen</DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        fullWidth
                        required
                        margin="normal"
                        name="name"
                        label="Neuer Gerätename"
                        autoComplete="off"
                        value={values.name}
                        onChange={handleChange}
                        error={errors.name !== undefined && touched.name}
                        helperText={errors.name && touched.name && errors.name}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseRenameDialog} color="primary">
                        Abbruch
                      </Button>
                      <Button type="submit" color="primary">
                        Speichern
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </Dialog>
            <Dialog open={updateThingDialogOpen} onClose={handleCloseUpdateThingDialog}>
              <Formik
                initialValues={{ selectedRoom: thing.room, newRoom: '' }}
                onSubmit={updateThingRoomClicked}
                validate={updateRoomValidate}
              >
                {({ values, errors, touched, handleSubmit, handleChange }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <DialogTitle>Neuen Raum zuweisen</DialogTitle>
                    <DialogContent>
                      <FormControl
                        fullWidth
                        error={errors.selectedRoom !== undefined && touched.selectedRoom}
                      >
                        <InputLabel>Raum auswähnen</InputLabel>
                        <Select
                          value={values.selectedRoom}
                          name="selectedRoom"
                          onChange={handleChange}
                        >
                          {data.listThings
                            .reduce((roomList: string[], currThing) => {
                              if (currThing.room && roomList.indexOf(currThing.room) === -1)
                                roomList.push(currThing.room);
                              return roomList;
                            }, [])
                            .map((room) => (
                              <MenuItem key={room} value={room}>
                                {room}
                              </MenuItem>
                            ))}
                          <MenuItem value={selectNewRoom}>Neuern Raum erstellen</MenuItem>
                        </Select>
                        {errors.selectedRoom && touched.selectedRoom && (
                          <FormHelperText>{errors.selectedRoom}</FormHelperText>
                        )}
                      </FormControl>
                      {values.selectedRoom === selectNewRoom && (
                        <TextField
                          autoFocus
                          fullWidth
                          required
                          margin="normal"
                          name="newRoom"
                          label="Name des neuen Raumes"
                          autoComplete="off"
                          value={values.newRoom}
                          onChange={handleChange}
                          error={errors.newRoom !== undefined && touched.newRoom}
                          helperText={errors.newRoom && touched.newRoom && errors.newRoom}
                        />
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseUpdateThingDialog} color="primary">
                        Abbruch
                      </Button>
                      <Button type="submit" color="primary">
                        Speichern
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </Dialog>
          </>
        )}
        {data && networkStatus === NetworkStatus.ready && thing === undefined && (
          <Alert severity="warning" className={classes.alertMargin}>
            Es konte kein Gerät mit der angegebenen id gefunden werden.
          </Alert>
        )}
      </Container>
    </>
  );
}
