import React from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListSubheader,
  makeStyles,
  createStyles,
  CircularProgress,
  Button,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import GoogleAssistantLogo from '../../images/google_assistant_logo_24px.png';
import AmazonAlexaLogo from '../../images/amazon_alexa_logo_24px.png';
import { useAuthCtx } from '../../context/auth';
import { ListThingsData, LIST_THING_QUERY, Thing } from '../../api/things';
import * as Constants from '../../utils/constants';
import { GetGeneralData, GET_GENERAL_QUERY } from '../../api/general';

const useStyles = makeStyles(() =>
  createStyles({
    marginTop: {
      marginTop: '3%',
    },
    spinner: {
      marginLeft: 20,
    },
  })
);

export default function Settings() {
  const classes = useStyles();

  const { loading, error, data } = useQuery<ListThingsData, undefined>(LIST_THING_QUERY);
  if (error) console.log(JSON.stringify(error));
  if (data) console.log(data);

  const { loading: getGeneralLoading, error: getGeneralError, data: getGeneralData } = useQuery<
    GetGeneralData
  >(GET_GENERAL_QUERY);
  if (getGeneralError) console.log(JSON.stringify(getGeneralError));
  if (getGeneralData) console.log(getGeneralData);

  const getLocationString = () => {
    if (getGeneralError) return `Error: ${getGeneralError.message}`;
    if (getGeneralLoading) return 'Loading...';
    if (getGeneralData)
      return `Latitude: ${getGeneralData.getGeneral.latitude}, Longitude: ${getGeneralData.getGeneral.longitude}`;
    return 'Error';
  };

  const getHouseNameString = () => {
    if (getGeneralError) return `Error: ${getGeneralError.message}`;
    if (getGeneralLoading) return 'Loading...';
    if (getGeneralData) return getGeneralData.getGeneral.houseName;
    return 'Error';
  };

  const getGoogleAssistantConnectedString = () => {
    if (getGeneralError) return `Error: ${getGeneralError.message}`;
    if (getGeneralLoading) return 'Loading...';
    if (getGeneralData)
      return getGeneralData.getGeneral.googleAssistantConnected ? 'Verbunden' : 'Nicht Verbunden';
    return 'Error';
  };

  return (
    <Container className={classes.marginTop} component="main" maxWidth="md">
      <List
        subheader={
          <ListSubheader color="primary" disableSticky>
            Zum Zuhause hinzufügen
          </ListSubheader>
        }
      >
        <ListItem button component={Link} to="/settings/create-thing">
          <ListItemIcon>
            <AddCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Gerät einrichten" />
        </ListItem>
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader color="primary" disableSticky>
            Allgemein
          </ListSubheader>
        }
      >
        <ListItem button>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Alias für mein Zuhause" secondary={getHouseNameString()} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <EditLocationIcon />
          </ListItemIcon>
          <ListItemText primary="Standort" secondary={getLocationString()} />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Benachrichtigungen"
            secondary="Einstellungen für eingehende Benachrichtungen festlegen"
          />
        </ListItem>
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader color="primary" disableSticky>
            Räume und Geräte
          </ListSubheader>
        }
      >
        {error && (
          <Alert
            severity="error"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  console.log('refetching');
                  window.location.reload(); // TODO only reload home component
                }}
              >
                Retry
              </Button>
            }
          >
            {error.message}
          </Alert>
        )}
        {loading && <CircularProgress className={classes.spinner} />}
        {data &&
          data.listThings.length > 0 &&
          Object.entries(
            data.listThings.reduce((roomMap: Record<string, Thing[]>, thing) => {
              (roomMap[thing.room || Constants.OtherDevices] =
                roomMap[thing.room || Constants.OtherDevices] || []).push(thing);
              return roomMap;
            }, {})
          )
            .sort(([roomName], [roomNameComp]) => {
              if (roomName === Constants.OtherDevices) return 1;
              if (roomNameComp === Constants.OtherDevices) return -1;
              return roomName.localeCompare(roomNameComp);
            })
            .map(([roomName, roomThings]) => (
              <ListItem
                button
                component={Link}
                to={`/settings/room-settings/${encodeURIComponent(roomName)}`}
                key={roomName}
              >
                <ListItemText
                  primary={roomName}
                  secondary={`${roomThings.length} ${roomThings.length === 1 ? 'Gerät' : 'Geräte'}`}
                />
              </ListItem>
            ))}
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader color="primary" disableSticky>
            Digitale Assistenten
          </ListSubheader>
        }
      >
        <ListItem button>
          <ListItemIcon>
            <img src={GoogleAssistantLogo} alt="Google Assistent Logo" />
          </ListItemIcon>
          <ListItemText
            primary="Google Assistent"
            secondary={getGoogleAssistantConnectedString()}
          />
        </ListItem>
        {/* <ListItem button>
          <ListItemIcon>
            <img src={AmazonAlexaLogo} alt="Amazon Alexa Logo" />
          </ListItemIcon>
          <ListItemText primary="Amazon Alexa" secondary="Nicht verbunden" />
        </ListItem> */}
      </List>
    </Container>
  );
}
