import React, { useState } from 'react';
import {
  Fab,
  makeStyles,
  Theme,
  createStyles,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  ListItemIcon,
  IconButton,
  Button,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  AutomationRuleSimple,
  DeleteAutomationData,
  DeleteAutomationInput,
  DELETE_AUTOMATION_MUTATION,
  ListAutomationData,
  LIST_AUTOMATION_QUERY,
  SetEnabledAutomationData,
  SetEnabledAutomationInput,
  SET_ENABLED_AUTOMATION_MUTATION,
} from '../../api/automation';

function generateLastExecutedString(lastExecuted?: string) {
  if (!lastExecuted) return 'nie';
  const timestamp = new Date(lastExecuted);
  if (Number.isNaN(Number(timestamp))) return 'Fehler: ungültiger Timestamp';
  return timestamp.toLocaleString('de-DE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(4),
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(9),
      right: theme.spacing(2),
    },
    alertMargin: {
      marginTop: '5%',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

const Automation = () => {
  const classes = useStyles();

  const {
    loading: listAutomationsLoading,
    error: listAutomationsError,
    data: listAutomationsData,
  } = useQuery<ListAutomationData, undefined>(LIST_AUTOMATION_QUERY);
  if (listAutomationsError) console.log(JSON.stringify(listAutomationsError));
  if (listAutomationsData) console.log(listAutomationsData);

  const [
    deleteAutomation,
    { error: deleteAutomationError, loading: deleteAutomationLoading },
  ] = useMutation<DeleteAutomationData, DeleteAutomationInput>(DELETE_AUTOMATION_MUTATION, {
    update(cache, { data }) {
      const delAutomation = data?.deleteAutomation;
      if (!delAutomation) return;
      cache.modify({
        fields: {
          listAutomations(existingAutomationRefs = [], { readField }) {
            return existingAutomationRefs.filter(
              (automationRef: any) => delAutomation.id !== readField('id', automationRef)
            );
          },
        },
      });
    },
  });

  const [
    setEnableAutomation,
    { error: setEnableAutomationError, loading: setEnableAutomationLoading },
  ] = useMutation<SetEnabledAutomationData, SetEnabledAutomationInput>(
    SET_ENABLED_AUTOMATION_MUTATION
  );

  const [deleteRuleObj, setDeleteRuleId] = useState<undefined | AutomationRuleSimple>(undefined);

  const handleCloseDeleteDialog = () => {
    setDeleteRuleId(undefined);
  };

  const deleteConfirmed = async () => {
    if (deleteRuleObj === undefined) return;
    try {
      await deleteAutomation({
        variables: {
          id: deleteRuleObj.id,
        },
      });
    } catch (err) {
      console.log(err);
    }
    handleCloseDeleteDialog();
  };

  const handleEnabledChange = (ruleId: string) => async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const res = await setEnableAutomation({
        variables: {
          id: ruleId,
          enabled: event.target.checked,
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  const history = useHistory();
  const openAutomationEdit = (ruleId: string) => {
    history.push(`/automation/edit/${ruleId}`);
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Backdrop
        className={classes.backdrop}
        open={deleteAutomationLoading || setEnableAutomationLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {listAutomationsLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.alertMargin}>
          <CircularProgress />
        </div>
      )}
      {(listAutomationsError || deleteAutomationError || setEnableAutomationError) && (
        <Alert
          severity="error"
          className={classes.alertMargin}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                console.log('refetching');
                window.location.reload(); // TODO only reload home component
              }}
            >
              Retry
            </Button>
          }
        >
          <AlertTitle>Error</AlertTitle>
          {listAutomationsError?.message ??
            deleteAutomationError?.message ??
            setEnableAutomationError?.message}
        </Alert>
      )}
      {listAutomationsData &&
        (listAutomationsData.listAutomations.length === 0 ? (
          <Alert
            severity="info"
            className={classes.alertMargin}
            action={
              <Button color="inherit" size="small" component={Link} to="/automation/new">
                REGEL HINZUFÜGEN
              </Button>
            }
          >
            Sie haben noch keine Automatisierungsregel eingerichtet.
          </Alert>
        ) : (
          <List>
            {listAutomationsData.listAutomations.map((rule) => (
              <ListItem
                key={rule.id}
                button // TODO fix (enable switch shouldn't trigger this button)
                // component={Link} to={`/automation/edit/${rule.id}`}
              >
                <ListItemIcon>
                  <Switch
                    edge="end"
                    color="primary"
                    checked={rule.enabled}
                    onChange={handleEnabledChange(rule.id)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={rule.name}
                  onClick={() => openAutomationEdit(rule.id)}
                  secondary={`Zuletzt ausgeführt: ${generateLastExecutedString(rule.lastExecuted)}`}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => setDeleteRuleId(rule)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ))}
      <Dialog open={deleteRuleObj !== undefined} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Gerät {deleteRuleObj?.name} wirklich löschen</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Abbruch
          </Button>
          <Button onClick={deleteConfirmed} color="primary">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      <Fab color="primary" className={classes.fab} component={Link} to="/automation/new">
        <AddIcon />
      </Fab>
    </Container>
  );
};

export default Automation;
