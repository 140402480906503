import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  Card,
  CardHeader,
  Switch,
  CardActions,
  Slider,
} from '@material-ui/core';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import { useMutation } from '@apollo/client';
import { ThingCardProps } from './thing';
import { PerformActionData, PERFORM_ACTION_MUTATION, PerformActionInput } from '../../api/action';
import { Thing, ThingTrait } from '../../api/things';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 100,
      minHeight: 110,
    },
    slider: {
      marginLeft: 15,
      marginRight: 15,
      width: 220,
    },
    cardHeader: {
      marginBottom: -20,
    },
  })
);

const marks = [
  { value: 1, label: '1%' },
  { value: 20, label: '20%' },
  { value: 50, label: '50%' },
  { value: 100, label: '100%' },
];

function getSliderValue(thing: Thing) {
  if (
    thing.availableActions.includes(ThingTrait.Brightness) &&
    typeof thing.properties.brightness === 'number' &&
    Number.isInteger(thing.properties.brightness) &&
    thing.properties.brightness > 0 &&
    thing.properties.brightness <= 100
  )
    return thing.properties.brightness;
  return undefined;
}

export default function ThingLight({ thing }: ThingCardProps) {
  const classes = useStyles();

  const [performAction, { error }] = useMutation<PerformActionData, PerformActionInput>(
    PERFORM_ACTION_MUTATION
  );
  if (error) console.log(JSON.stringify(error));

  const [sliderValue, setSliderValue] = useState<number | undefined>(getSliderValue(thing));

  useEffect(() => {
    setSliderValue(getSliderValue(thing));
  }, [thing]);

  function handleSliderChange(event: React.ChangeEvent<{}>, newVal: number | number[]) {
    setSliderValue(newVal as number);
  }

  async function handleSliderChangeCommitted(
    event: React.ChangeEvent<{}>,
    newVal: number | number[]
  ) {
    setSliderValue(thing.properties.brightness);
    try {
      const res = await performAction({
        variables: {
          action: {
            thingId: thing.id,
            trait: ThingTrait.Brightness,
            input: {
              brightness: newVal,
            },
          },
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>) {
    try {
      const res = await performAction({
        variables: {
          action: {
            thingId: thing.id,
            trait: ThingTrait.OnOff,
            input: {
              on: event.target.checked,
            },
          },
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  const switchState = thing.properties.on;
  if (!thing.availableActions.includes(ThingTrait.OnOff) || typeof switchState !== 'boolean') {
    console.log('invalid light:', thing);
    return <></>;
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<WbIncandescentIcon />}
        action={
          sliderValue && (
            <Switch checked={switchState} color="primary" onChange={handleSwitchChange} />
          )
        }
        className={classes.cardHeader}
        title={thing.name}
      />
      <CardActions>
        {sliderValue !== undefined ? (
          <Slider
            className={classes.slider}
            value={sliderValue}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommitted}
            step={10}
            min={1}
            max={100}
            valueLabelDisplay="auto"
            marks={marks}
          />
        ) : (
          <Switch checked={switchState} color="primary" onChange={handleSwitchChange} />
        )}
      </CardActions>
    </Card>
  );
}
