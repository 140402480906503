import { useQuery } from '@apollo/client';
import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { GetGeneralData, GET_GENERAL_QUERY } from '../../api/general';
import { useAuthCtx } from '../../context/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingError: {
      marginTop: '5%',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

type RedirectRenderRoute = {
  routerProps: RouteProps;
  redirectPath: string;
};

function RedirectRenderRoute({ routerProps, redirectPath }: RedirectRenderRoute) {
  return (
    <Route
      {...routerProps}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: location },
          }}
        />
      )}
    />
  );
}

function IsAuthenticatedRoute({
  forceNotSetup = false,
  component,
  ...rest
}: RouteProps & PrivateRouteProps) {
  const classes = useStyles();
  const { loading: getGeneralLoading, error: getGeneralError, data: getGeneralData } = useQuery<
    GetGeneralData
  >(GET_GENERAL_QUERY); // TODO use lazy query

  const getRenderedRouteRedirect = (isSetup: boolean) => {
    if (isSetup) return <Route component={component} {...rest} />;
    if (forceNotSetup) return <RedirectRenderRoute routerProps={rest} redirectPath="/app/home" />;
    return <RedirectRenderRoute routerProps={rest} redirectPath="/setup/general-setup" />;
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={getGeneralLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {getGeneralError && (
        <Alert
          severity="error"
          className={classes.loadingError}
          action={
            <Button color="inherit" size="small" onClick={window.location.reload}>
              Retry
            </Button>
          }
        >
          <AlertTitle>Error</AlertTitle>
          {getGeneralError.message}
        </Alert>
      )}
      {getGeneralData && getRenderedRouteRedirect(getGeneralData.getGeneral.isSetup)}
    </>
  );
}

type PrivateRouteProps = {
  forceNotSetup?: boolean;
};

export default function PrivateRoute({
  forceNotSetup = false,
  component,
  ...rest
}: RouteProps & PrivateRouteProps) {
  const { isAuthenticated } = useAuthCtx();
  if (isAuthenticated)
    return <IsAuthenticatedRoute forceNotSetup={forceNotSetup} component={component} {...rest} />;
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: '/auth/signin',
            state: { from: location },
          }}
        />
      )}
    />
  );
}
