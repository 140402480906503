import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ApolloProviderProps } from '@apollo/client/react/context';
import { getMainDefinition } from '@apollo/client/utilities';
import React from 'react';
import { useAuthCtx } from '../context/auth';
import * as Constants from '../utils/constants';
import ThingSubscriber from './ThingSubscriber';

export default function ApolloWrapper(apolloProps: Omit<ApolloProviderProps<any>, 'client'>) {
  const { isAuthenticated, logout } = useAuthCtx();

  if (isAuthenticated) {
    const httpLink = new HttpLink({
      uri: Constants.GQLBackendHTTP,
      credentials:
        window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
          ? undefined
          : 'include',
    });

    const wsLink = new WebSocketLink({
      uri: Constants.GQLBackendWS,
      options: {
        reconnect: true,
        timeout: 15000,
      },
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLink
    );

    const client = new ApolloClient({
      link: from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
          if (networkError) console.log(`[Network error]: ${networkError}`);
          if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
            console.log('Apollo network auth error logging out...');
            logout().then((msg) => {
              console.log('Apollo logout cb: ', msg);
            });
          }
        }),
        splitLink,
      ]),
      cache: new InMemoryCache(),
    });

    return (
      <ApolloProvider {...apolloProps} client={client}>
        <ThingSubscriber />
        {apolloProps.children}
      </ApolloProvider>
    );
  }
  return <>{apolloProps.children}</>;
}
