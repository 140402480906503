import { gql } from '@apollo/client';

export enum ThingEvent {
  TemperatureUpdate = 'TemperatureUpdate',
  HumidityUpdate = 'HumidityUpdate',
  SoilMoistureUpdate = 'SoilMoistureUpdate',
  LightChange = 'LightChange',
}

export type EventLog = {
  id: string;
  thingId: string;
  type: string;
  properties: Record<string, any>;
  createdAt: Date;
};

export type ListAllEventsData = {
  listAllEvents: EventLog[];
};

export const LIST_ALL_EVENTS_QUERY = gql`
  {
    listAllEvents {
      id
      thingId
      type
      properties
      createdAt
    }
  }
`;


export type ListFilteredEvents = {
  listFilteredEvents: EventLog[];
};

export type ListFilteredEventsInput = {
  thingId: string;
  eventType: ThingEvent;
  startTime: Date;
  endTime: Date;
};

export const LIST_FILTERED_EVENTS_QUERY = gql`
  query listFilteredEvents($thingId: UUID!, $eventType: ThingEvent!, $startTime: Time, $endTime: Time){
    listFilteredEvents(thingId: $thingId, eventType: $eventType, startTime: $startTime, endTime: $endTime) {
        id
        thingId
        type
        properties
        createdAt
    }
}
`;