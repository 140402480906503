import { gql } from '@apollo/client';

export enum ThingType {
  Light = 'Light',
  Shutter = 'Shutter',
  Ventilation = 'Ventilation',
  Irrigation = 'Irrigation',
  TemperatureSensor = 'TemperatureSensor',
  HumiditySensor = 'HumiditySensor',
  SoilMoistureSensor = 'SoilMoistureSensor',
  PressureSensor = 'PressureSensor',
  WaterLevelSensor = 'WaterLevelSensor',
}

export enum ThingTrait {
  OnOff = 'OnOff',
  Brightness = 'Brightness',
  OpenClose = 'OpenClose',
  Stop = 'Stop',
  FanSpeed = 'FanSpeed',
  Timer = 'Timer',
}
export function ThingTraitToString(trait: ThingTrait) {
  switch (trait) {
    case ThingTrait.OnOff:
      return 'An/Aus';
    case ThingTrait.Brightness:
      return 'Helligkeit';
    case ThingTrait.OpenClose:
      return 'Auf/Zu';
    case ThingTrait.Stop:
      return 'Stop';
    case ThingTrait.FanSpeed:
      return 'Gebläse Geschwindikeit';
    case ThingTrait.Timer:
      return 'Timer';
    default:
      return `Not implemented: ${trait}`;
  }
}

export type Thing = {
  id: string;
  adapter: string;
  adapterThingID: string;
  name: string;
  type: ThingType;
  room: string;
  properties: Record<string, any>;
  availableActions: ThingTrait[];
  lastEventTime: Date;
  lastActionTime: Date;
  updatedAt: Date;
  createdAt: Date;
};

export function extendedThingType(thing: Thing) {
  switch (thing.type) {
    case ThingType.Light:
      if (thing.availableActions.includes(ThingTrait.Brightness)) return 'Dimbares Licht';
      return 'Licht';
    case ThingType.Shutter:
      return 'Rollladen';
    case ThingType.Ventilation:
      return 'Lüftung';
    case ThingType.Irrigation:
      return 'Gießanlage';
    case ThingType.TemperatureSensor:
      return 'Temperatursensor';
    case ThingType.HumiditySensor:
      return 'Luftfeuchtigkeitssensor';
    case ThingType.SoilMoistureSensor:
      return 'Bodenfeuchtigkeitssensor';
    case ThingType.PressureSensor:
      return 'Drucksensor';
    case ThingType.WaterLevelSensor:
      return 'Wasserstandssensor';
    default:
      return `Not implemented: ${thing.type}`;
  }
}

export const THING_ATTRIBUTES = gql`
  fragment thingAttributes on Thing {
    id
    adapter
    adapterThingID
    name
    room
    type
    properties
    availableActions
    lastEventTime
    lastActionTime
    updatedAt
    createdAt
  }
`;

export type ListThingsData = {
  listThings: Thing[];
};

export const LIST_THING_QUERY = gql`
  {
    listThings {
      ...thingAttributes
    }
  }
  ${THING_ATTRIBUTES}
`;

export type ScanForThingsData = {
  scanForThings: Thing[];
};

export const SCAN_FOR_THINGS_QUERY = gql`
  {
    scanForThings {
      id
      adapter
      adapterThingID
      type
      availableActions
    }
  }
`;

export type CreateThingData = {
  createThing: Thing;
};

export type CreateThingInput = {
  id: string;
  name: string;
  roomName: string;
};

export const CREATE_THING_MUTATION = gql`
  mutation createThing($id: UUID!, $name: String!, $roomName: String!) {
    createThing(id: $id, name: $name, roomName: $roomName) {
      ...thingAttributes
    }
  }
  ${THING_ATTRIBUTES}
`;

export type DeleteThingData = {
  deleteThing: Thing;
};

export type DeleteThingInput = {
  id: string;
};

export const DELETE_THING_MUTATION = gql`
  mutation deleteThing($id: UUID!) {
    deleteThing(id: $id) {
      ...thingAttributes
    }
  }
  ${THING_ATTRIBUTES}
`;

export type RenameThingData = {
  renameThing: Thing;
};

export type RenameThingInput = {
  id: string;
  name: string;
};

export const RENAME_THING_MUTATION = gql`
  mutation renameThing($id: UUID!, $name: String!) {
    renameThing(id: $id, name: $name) {
      ...thingAttributes
    }
  }
  ${THING_ATTRIBUTES}
`;

export type RenameRoomData = {
  renameRoom: Thing;
};

export type RenameRoomInput = {
  oldName: string;
  newName: string;
};

export const RENAME_ROOM_MUTATION = gql`
  mutation updateRoomName($oldName: String!, $newName: String!) {
    updateRoomName(oldName: $oldName, newName: $newName) {
      ...thingAttributes
    }
  }
  ${THING_ATTRIBUTES}
`;

export type UpdateThingRoomData = {
  deleteThing: Thing;
};

export type UpdateThingRoomInput = {
  id: string;
  roomName: string;
};

export const UPDATE_THING_ROOM_MUTATION = gql`
  mutation updateThingRoom($id: UUID!, $roomName: String!) {
    updateThingRoom(id: $id, roomName: $roomName) {
      ...thingAttributes
    }
  }
  ${THING_ATTRIBUTES}
`;

export type DeleteRoomData = {
  deleteRoom: Thing[];
};

export type DeleteRoomInput = {
  name: string;
};

export const DELETE_ROOM_MUTATION = gql`
  mutation deleteRoom($name: String!) {
    deleteRoom(name: $name) {
      ...thingAttributes
    }
  }
  ${THING_ATTRIBUTES}
`;

export enum ThingChangeType {
  ThingAdded = 'ThingAdded',
  ThingRemoved = 'ThingRemoved',
  ThingRename = 'ThingRename',
  ThingRoomChange = 'ThingRoomChange',
  ThingPropertyChange = 'ThingPropertyChange',
  ThingAllSync = 'ThingAllSync',
}

export type ThingChange = {
  thingId: string;
  type: ThingChangeType;
  newThing?: Thing;
  updatedName?: string;
  updatedRoom?: string;
  updatedProperties?: Record<string, any>;
  syncAll?: Thing[];
};

export type ThingChangeData = {
  thingChange: ThingChange;
};

export const THING_CHANGE_SUBSCRIPTION = gql`
  subscription thingChange {
    thingChange {
      thingId
      type
      newThing {
        ...thingAttributes
      }
      updatedName
      updatedRoom
      updatedProperties
      syncAll {
        ...thingAttributes
      }
    }
  }
  ${THING_ATTRIBUTES}
`;
