import React from 'react';
import { makeStyles, createStyles, Card, CardHeader, CardActions } from '@material-ui/core';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { ThingCardProps } from './thing';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 100,
      minHeight: 110,
    },
    cardHeader: {
      marginBottom: -20,
    },
  })
);

export default function ThingSoilMoistureSensor({ thing }: ThingCardProps) {
  const classes = useStyles();
  const { soilMoisture } = thing.properties;
  if (typeof soilMoisture !== 'number') {
    console.log('invalid soilMoistureSensor:', thing);
    return <></>;
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<AcUnitIcon />}
        // action={<p>{soilMoisture} %</p>}
        className={classes.cardHeader}
        title={thing.name}
      />
      <CardActions>
        <p>
          {soilMoisture} %
          <br />
          Zuletzt aktualisiert: {thing.updatedAt.toLocaleString('de-DE')}
        </p>
      </CardActions>
    </Card>
  );
}
