import { gql } from '@apollo/client';
import { ThingTrait } from './things';

export const AUTOMATION_ATTRIBUTES = gql`
  fragment automationAttributes on AutomationRule {
    id
    name
    enabled
    lastExecuted
  }
`;

export enum TriggerType {
  Time = 'time',
  // Device = 'device',
  Sun = 'sun',
}
export enum TriggerRecurrence {
  Minutely = 'minutely',
  Hourly = 'hourly',
  Dayly = 'dayly',
  Weekly = 'weekly',
}
export type TriggerWeekdayMap = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};
export function TriggerWeekdayToShortString(day: string) {
  switch (day) {
    case 'monday':
      return 'Mo';
    case 'tuesday':
      return 'Di';
    case 'wednesday':
      return 'Mi';
    case 'thursday':
      return 'Do';
    case 'friday':
      return 'Fr';
    case 'saturday':
      return 'Sa';
    case 'sunday':
      return 'So';
    default:
      return '?!';
  }
}
export function TriggerWeekdayMapToString(weekdayMap: TriggerWeekdayMap) {
  let res = '';
  Object.entries(weekdayMap).forEach(([key, value]) => {
    if (value === true) {
      if (res === '') res += TriggerWeekdayToShortString(key);
      else res += `, ${TriggerWeekdayToShortString(key)}`;
    }
  });
  return res;
}
export function TriggerRecurrenceToString(recurrence: TriggerRecurrence) {
  switch (recurrence) {
    case TriggerRecurrence.Dayly:
      return 'Täglich';
    case TriggerRecurrence.Weekly:
      return 'Wöchentlich';
    case TriggerRecurrence.Hourly:
      return 'Stündlich';
    case TriggerRecurrence.Minutely:
      return 'Minütlich';
    default:
      return 'Unbekantes Zeitintervall!';
  }
}

export type SunTriggerServer = {
  sunrise: boolean;
  offsetMin: number;
};

export type TimeTriggerServer = {
  recurrence: TriggerRecurrence;
  weekdays: TriggerWeekdayMap;
  time: Date;
};

export type AutomationTriggerServer = {
  type: TriggerType;
  time?: TimeTriggerServer;
  sun?: SunTriggerServer;
};

export enum ConditionType {
  Time = 'time',
  Device = 'device',
  Sun = 'sun',
}
export enum ConditionOperator {
  Equal = 'equal',
  Greater = 'greater',
  Less = 'less',
}
export function ConditionOperatorToString(operator: ConditionOperator) {
  switch (operator) {
    case ConditionOperator.Less:
      return 'Kleiner';
    case ConditionOperator.Equal:
      return 'Gleich';
    case ConditionOperator.Greater:
      return 'Größer';
    default:
      return 'Unbekanter Operator!';
  }
}

export type DeviceConditionServer = {
  deviceId: string;
  operator: ConditionOperator;
  propertyKey: string;
  value: any;
};

export type AutomationConditionServer = {
  type: ConditionType;
  device?: DeviceConditionServer;
};

export type AutomationActionServer = {
  deviceId: string;
  trait: ThingTrait;
  input: Record<string, any>;
};

export type AutomationRuleServerInput = {
  name: string;
  trigger: AutomationTriggerServer[];
  conditions: AutomationConditionServer[];
  actions: AutomationActionServer[];
};

export type AutomationRuleSimple = {
  id: string;
  name: string;
  enabled: boolean;
  lastExecuted?: string;
};

export type AutomationRuleServer = AutomationRuleSimple & {
  trigger: AutomationTriggerServer[];
  conditions: AutomationConditionServer[];
  actions: AutomationActionServer[];
};

export type ListAutomationData = {
  listAutomations: AutomationRuleSimple[];
};

export const LIST_AUTOMATION_QUERY = gql`
  {
    listAutomations {
      ...automationAttributes
    }
  }
  ${AUTOMATION_ATTRIBUTES}
`;

export type GetAutomationData = {
  getDetailedAutomation: AutomationRuleServer;
};

export type GetAutomationInput = {
  id: string;
};

export const GET_AUTOMATION_QUERY = gql`
  query getDetailedAutomation($id: UUID!) {
    getDetailedAutomation(id: $id) {
      id
      name
      trigger {
        type
        time {
          recurrence
          weekdays
          time
        }
        sun {
          sunrise
          offsetMin
        }
      }
      conditions {
        type
        device {
          deviceId
          operator
          propertyKey
          value
        }
      }
      actions {
        deviceId
        trait
        input
      }
      enabled
      lastExecuted
    }
  }
`;

export type CreateAutomationData = {
  createAutomation: AutomationRuleSimple;
};

export type CreateAutomationInput = {
  rule: AutomationRuleServerInput;
};

export const CREATE_AUTOMATION_MUTATION = gql`
  mutation createAutomation($rule: AutomationRuleInput!) {
    createAutomation(rule: $rule) {
      ...automationAttributes
    }
  }
  ${AUTOMATION_ATTRIBUTES}
`;

export type EditAutomationData = {
  createAutomation: AutomationRuleSimple;
};

export type EditAutomationInput = {
  id: string;
  rule: AutomationRuleServerInput;
};

export const EDIT_AUTOMATION_MUTATION = gql`
  mutation editAutomation($id: UUID!, $rule: AutomationRuleInput!) {
    editAutomation(id: $id, rule: $rule) {
      ...automationAttributes
    }
  }
  ${AUTOMATION_ATTRIBUTES}
`;

export type DeleteAutomationData = {
  deleteAutomation: AutomationRuleSimple;
};

export type DeleteAutomationInput = {
  id: string;
};

export const DELETE_AUTOMATION_MUTATION = gql`
  mutation deleteRoom($id: UUID!) {
    deleteAutomation(id: $id) {
      ...automationAttributes
    }
  }
  ${AUTOMATION_ATTRIBUTES}
`;

export type SetEnabledAutomationData = {
  setEnableAutomation: AutomationRuleSimple;
};

export type SetEnabledAutomationInput = {
  id: string;
  enabled: boolean;
};
export const SET_ENABLED_AUTOMATION_MUTATION = gql`
  mutation setEnableAutomation($id: UUID!, $enabled: Boolean!) {
    setEnableAutomation(id: $id, enabled: $enabled) {
      ...automationAttributes
    }
  }
  ${AUTOMATION_ATTRIBUTES}
`;
