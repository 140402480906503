import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NoMatch from './NoMatch';
import CustomAppBar from '../layout/CustomAppBar';

const ErrorLayout = () => (
  <div>
    <CustomAppBar iconType="back" />
    <Switch>
      <Route path="*" component={NoMatch} />
    </Switch>
  </div>
);
export default ErrorLayout;
