import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Copyright from './Copyright';
import Policies from './Policies';
import CustomAppBar from '../layout/CustomAppBar';

const PoliciesLayout = () => (
  <div>
    <CustomAppBar iconType="back" />
    <Switch>
      <Route path="/policies/copyright" component={Copyright} />
      <Route path="/policies" component={Policies} />
    </Switch>
  </div>
);
export default PoliciesLayout;
