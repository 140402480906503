// @ts-nocheck

import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  InputLabel,
  Select,
  FormControl
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useQuery } from '@apollo/client';
import { ListFilteredEvents, LIST_FILTERED_EVENTS_QUERY } from '../../api/eventLog';
import { LIST_THING_QUERY, ThingType } from '../../api/things';


const useStyles = makeStyles((theme) => ({
  text_yaxis: {
    dominantBaseline: 'central',
    textAlign: 'right',
    stroke: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  },
  text_xaxis: {
    textAnchor: 'middle',
    stroke: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  },
  svg_chart: {
    overflow: 'visible',
  },
  path: {
    stroke: theme.palette.text.primary,
    strokeWidth: '2px',
  },
  circle_ds1: {
    stroke: theme.palette.text.primary,
    fill: theme.palette.text.primary,
    r: '3px',
  },
  text_charttitle: {
    textAnchor: 'middle',
    fontSize: '20px',
    stroke: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  },
  alertMargin: {
    marginTop: '5%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    stroke: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const monthStr = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
const dayStr = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]

class TempData {
  constructor(data, cfgParam) {
    this.data = data
    this.cfgParam = cfgParam
  }

  eventList() {
    if (this.data.listFilteredEvents == undefined) {
      return []
    } else {
      return this.data.listFilteredEvents.filter(edate => ((edate.createdAt >= this.cfgParam.startDate) && (edate.createdAt < this.cfgParam.endDate)));
    }
  }

  get maxTemp() {
    const maxTemps = [45, 40, 35, 30, 25, 20, 15, 10, 5, 0];
    let tmp = this.eventList()
    if(tmp[0] == null) {
      return maxTemps[0]
    }
    let max = tmp.reduce((prevValue, event) => {
      if (event.properties.temperature > prevValue) {
        return event.properties.temperature
      } else {
        return prevValue
      }
    }, maxTemps[maxTemps.length - 1])
    return maxTemps.reduce((prevValue, temp) => {
      if (temp <= max) {
        return prevValue
      } else {
        return temp
      }
    }, maxTemps[0])
  }

  get minTemp() {
    const minTemps = [-30, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25];
    let tmp = this.eventList()
    if(tmp[0] == null) {
      return minTemps[0]
    }
    let min = tmp.reduce((prevValue, event) => {
      if (event.properties.temperature < prevValue) {
        return event.properties.temperature
      } else {
        return prevValue
      }
    }, minTemps[minTemps.length - 1])

    return minTemps.reduce((prevValue, temp) => {
      if (temp < min) {
        return temp
      } else {
        return prevValue
      }
    }, minTemps[0])
  }
  static extractTemp(event) {
    return event.properties.temperature
  }
  static extractTime(event) {
    return event.createdAt
  }
}

class MoistData {
  constructor(data, cfgParam) {
    this.data = data
    this.cfgParam = cfgParam
  }

  eventList() {
    if (this.data.listFilteredEvents == undefined) {
      return []
    } else {
      return this.data.listFilteredEvents.filter(edate => ((edate.createdAt >= this.cfgParam.startDate) && (edate.createdAt < this.cfgParam.endDate)));
    }
  }

  get maxMoist() {
    const maxMoist = [100, 80, 60, 40, 20, 0];
    let tmp = this.eventList()
    if(tmp[0] == null) {
      return maxMoist[0]
    }
    let max = tmp.reduce((prevValue, event) => {
      if (event.properties.humidity > prevValue) {
        return event.properties.humidity
      } else {
        return prevValue
      }
    }, maxMoist[maxMoist.length - 1])
    return maxMoist.reduce((prevValue, moist) => {
      if (moist <= max) {
        return prevValue
      } else {
        return moist
      }
    }, maxMoist[0])
  }

  get minMoist() {
    const minMoist = [0, 20, 40, 60, 80, 100];
    let tmp = this.eventList()
    if(tmp[0] == null) {
      return minMoist[0]
    }
    let min = tmp.reduce((prevValue, event) => {
      
      if (event.properties.humidity < prevValue) {
        return event.properties.humidity
      } else {
        return prevValue
      }
    }, minMoist[minMoist.length - 1])

    return minMoist.reduce((prevValue, moist) => {
      if (moist < min) {
        return moist
      } else {
        return prevValue
      }
    }, minMoist[0])
  }
  static extractMoist(event) {
    return event.properties.humidity
  }
  static extractTime(event) {
    return event.createdAt
  }
}

class SoilMoistData {
  constructor(data, cfgParam) {
    this.data = data
    this.cfgParam = cfgParam
  }

  eventList() {
    if (this.data.listFilteredEvents == undefined) {
      return []
    } else {
      return this.data.listFilteredEvents.filter(edate => ((edate.createdAt >= this.cfgParam.startDate) && (edate.createdAt < this.cfgParam.endDate)));
    }
  }

  get maxMoist() {
    const maxMoist = [100, 80, 60, 40, 20, 0];
    let tmp = this.eventList()
    if(tmp[0] == null) {
      return maxMoist[0]
    }
    let max = tmp.reduce((prevValue, event) => {
      if (event.properties.soilMoisture > prevValue) {
        return event.properties.soilMoisture
      } else {
        return prevValue
      }
    }, maxMoist[maxMoist.length - 1])
    return maxMoist.reduce((prevValue, moist) => {
      if (moist <= max) {
        return prevValue
      } else {
        return moist
      }
    }, maxMoist[0])
  }

  get minMoist() {
    const minMoist = [0, 20, 40, 60, 80, 100];
    let tmp = this.eventList()
    if(tmp[0] == null) {
      return minMoist[0]
    }
    let min = tmp.reduce((prevValue, event) => {
      
      if (event.properties.soilMoisture < prevValue) {
        return event.properties.soilMoisture
      } else {
        return prevValue
      }
    }, minMoist[minMoist.length - 1])

    return minMoist.reduce((prevValue, moist) => {
      if (moist < min) {
        return moist
      } else {
        return prevValue
      }
    }, minMoist[0])
  }
  static extractMoist(event) {
    return event.properties.soilMoisture
  }
  static extractTime(event) {
    return event.createdAt
  }
}


class myTimeClass extends Date {
  constructor() {
    if(arguments.length == 0) {
      super();
    } else {
      super(arguments[0].getTime());
    }
  }

  get beginOfDay() {
    let tmpTime = new myTimeClass(this)
    tmpTime.setHours(0, 0, 0, 0);
    return tmpTime;
  }
  get endOfDay() {
    let tmpTime = new myTimeClass(this)
    tmpTime.setHours(23, 59, 59, 0);
    return tmpTime;
  }
  get nextDay() {
    let tmpTime = new myTimeClass(this)
    tmpTime.setDate(tmpTime.getDate() + 1);
    return tmpTime;
  }
  get prevDay() {
    let tmpTime = new myTimeClass(this)
    tmpTime.setDate(tmpTime.getDate() - 1);
    return tmpTime;
  }
  get beginOfWeek() {
    let tmpTime = new myTimeClass(this);
    tmpTime.setHours(0, 0, 0, 0)
    tmpTime.setDate(tmpTime.getDate() - tmpTime.getDay());
    return tmpTime
  }
  get endOfWeek() {
    let tmpTime = new myTimeClass(this)
    tmpTime.setHours(23, 59, 59)
    tmpTime.setDate(tmpTime.getDate() - tmpTime.getDay() + 7);
    return tmpTime
  }
  get nextWeek() {
    let tmpTime = new myTimeClass(this)
    tmpTime.setDate(tmpTime.getDate() + 7);
    return tmpTime
  }
  get prevWeek() {
    let tmpTime = new myTimeClass(this)
    tmpTime.setDate(tmpTime.getDate() - 7);
    return tmpTime
  }
  get beginOfMonth() {
    let tmpTime = new myTimeClass(this);
    tmpTime.setHours(0, 0, 0, 0);
    tmpTime.setDate(1);
    return tmpTime
  }
  get endOfMonth() {
    let tmpTime = new myTimeClass(this);
    tmpTime.setMonth(tmpTime.getMonth() + 1);
    tmpTime.setDate(0);
    tmpTime.setHours(23, 59, 59, 0);
    return tmpTime;
  }
  get nextMonth() {
    let tmpTime = new myTimeClass(this);
    tmpTime.setMonth(tmpTime.getMonth() + 1);
    return tmpTime
  }
  get prevMonth() {
    let tmpTime = new myTimeClass(this);
    tmpTime.setMonth(tmpTime.getMonth() - 1);
    return tmpTime
  }
  get beginOfYear() {
    let tmpTime = new myTimeClass(this);
    tmpTime.setHours(0, 0, 0, 0);
    tmpTime.setDate(1);
    tmpTime.setMonth(0);
    return tmpTime
  }
}

export default function Statistics() {

  const [selectedDate, handleDateChange] = useState(new Date());
  const [sensorName, handleSensorNameChange] = React.useState('1');
  const handleRoomChange = (event) => {
    handleSensorNameChange(event.target.value);
  };
  const [timeFrame, handleTimeFrameChange] = React.useState('Tag');
  const handleTimeChange = (event) => {
    handleTimeFrameChange(event.target.value);
  };

  function calcTimeFrames(timeFrame, beginDate) {
    let currentDate = new myTimeClass(beginDate);
    let startDate;
    let endDate;

    if (timeFrame == "Tag") {
      startDate = currentDate.beginOfDay;
      endDate = currentDate.beginOfDay.nextDay;
    } else if (timeFrame == "Woche") {
      startDate = currentDate.beginOfWeek;
      endDate = (new myTimeClass(currentDate.beginOfWeek)).nextWeek;
    } else if (timeFrame == "Monat") {
      startDate = currentDate.beginOfMonth;
      endDate = (new myTimeClass(currentDate.beginOfMonth)).nextMonth;
    } else if (timeFrame == "Jahr") {
      console.log("problem")
    } else {
      console.log("problem")
    }

    return ({ startDate: startDate, endDate: endDate });
  }

  function myFormatDate(myDate) {
    return `${myDate.getFullYear()}-${monthStr[myDate.getMonth()]}-${dayStr[myDate.getDate()]}`
  }

  let myTime = new myTimeClass(selectedDate)
  let selectedTime = calcTimeFrames(timeFrame, selectedDate);


  function sensorsOfInterest(sensorName) {
    if(sensorName == ThingType.TemperatureSensor) {
      return true
    } else if(sensorName == ThingType.HumiditySensor) {
      return true
    } else if(sensorName == ThingType.SoilMoistureSensor) {
      return true
    } else if(sensorName == ThingType.PressureSensor) {
      return false
    } else if(sensorName == ThingType.WaterLevelSensor) {
      return false
    } else {
      return false
    }
  }

  let cfgParam = {
    maxHeight: 550,
    maxWidth: 550,
    type: timeFrame,
    yType: ThingType.TemperatureSensor,
    startDate: myFormatDate(selectedTime.startDate),
    endDate: myFormatDate(selectedTime.endDate),
    minYValue: 15,
    maxYValue: 30,
    minYPx: 520,
    maxYPx: 100,

    minXPx: 0,
    maxXPx: 480,
  }

  var eventType = "TemperatureUpdate";
  const startTime = selectedTime.startDate
  const endTime = selectedTime.endDate
  
  const { loading: loadingThings, error: errorThings, data: dataThings } =
    useQuery<ListThingsData, undefined>(LIST_THING_QUERY);

  if (dataThings) {
    if(dataThings?.listThings.filter(t => sensorsOfInterest(t.type))[sensorName].type == ThingType.HumiditySensor) {
      eventType = "HumidityUpdate";
    } else if(dataThings?.listThings.filter(t => sensorsOfInterest(t.type))[sensorName].type == ThingType.TemperatureSensor) {
      eventType = "TemperatureUpdate";
    } else if(dataThings?.listThings.filter(t => sensorsOfInterest(t.type))[sensorName].type == ThingType.SoilMoistureSensor) {
      eventType = "SoilMoistureUpdate";
    }
  } 

  const { loading: loadingEvents, error: errorEvents, data: dataEvents } =
    useQuery(LIST_FILTERED_EVENTS_QUERY, {
      variables: {
        thingId: dataThings?.listThings.filter(t => sensorsOfInterest(t.type))[sensorName].id,
        eventType,
        startTime,
        endTime
      },
      skip: !dataThings
    });


  function circProg() {
    return <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.alertMargin}>
      <CircularProgress />
    </div>
  }

  function handleErr(message) {
    return <Alert
      severity="error"
      className={classes.alertMargin}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            console.log('refetching');
            window.location.reload(); // TODO only reload home component
          }}
        >
          Retry
        </Button>
      }
    >
      <AlertTitle>Error</AlertTitle>
      {message}
    </Alert >
  }


  function handleEvt() {
    //let rooms = dataThings?.listThings.filter(t => t.type === ThingType.TemperatureSensor)
    let rooms = dataThings?.listThings.filter(t => sensorsOfInterest(t.type))
    if (!rooms) {
      rooms = []
    }

    var data = null;
    cfgParam.yType = dataThings?.listThings.filter(t => sensorsOfInterest(t.type))[sensorName].type
    if(cfgParam.yType == ThingType.TemperatureSensor) {
      data = new TempData(dataEvents, cfgParam)
      cfgParam.minYValue = data.minTemp
      cfgParam.maxYValue = data.maxTemp
    } else if(cfgParam.yType == ThingType.HumiditySensor) {
      let str = JSON.stringify(dataEvents);
      console.log("data Events: " + str)
      data = new MoistData(dataEvents, cfgParam)
      cfgParam.minYValue = data.minMoist
      cfgParam.maxYValue = data.maxMoist
    } else if(cfgParam.yType == ThingType.SoilMoistureSensor) {
      let str = JSON.stringify(dataEvents);
      console.log("data Events: " + str)
      data = new SoilMoistData(dataEvents, cfgParam)
      cfgParam.minYValue = data.minMoist
      cfgParam.maxYValue = data.maxMoist
    }
    
    cfgParam.name = dataThings?.listThings.filter(t => sensorsOfInterest(t.type))[sensorName].name
    
    //cfgParam.name = dataThings?.listThings.filter(t => t.type === ThingType.TemperatureSensor)[sensorName].name
    return <div>
      
      <FormControl className={classes.formControl}>
        <InputLabel id="select-room-name-label">room name</InputLabel>
        <Select
          labelId="select-room-name-label"
          id="select-room-name"
          value={sensorName}
          onChange={handleRoomChange}
        >
          {rooms.map((room, index) => (
            <MenuItem key={room.id} value={index}>
              {room.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel id="select-time-frame-label">time frame</InputLabel>
        <Select
          labelId="select-time-frame-label"
          id="select-time-frame"
          value={timeFrame}
          onChange={handleTimeChange}
        >
          {["Tag", "Woche", "Monat"].map((myTime, index) => (
            <MenuItem key={myTime} value={myTime}>
              {myTime}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <DatePicker value={selectedDate} onChange={handleDateChange} variant="inline" disableToolbar />
      </MuiPickersUtilsProvider >
      <br />
      <svg id="mychart" width="600" height="600">
        <svg className={classes.svg_chart} x="50" y="0" width="550" height="550" viewBox="0 0 550 550">
          <MyChart cfgParam={cfgParam} data={data} />
        </svg>
      </svg>
    </div>
  }

  const classes = useStyles();
  return (
    <Container component="main" maxWidth="md" >
      {loadingEvents && circProg()}
      {errorEvents && handleErr(errorEvents.message)}
      {dataEvents && handleEvt()}
    </Container >
  )
}

class BaseChart {
  constructor(cfgParam, styleClass) {
    this.cfgParam = cfgParam;
    this.styleClass = styleClass;
  }

  xaxis() {
    return <path className={this.styleClass.path} d={`M${this.cfgParam.minXPx} ${this.cfgParam.minYPx} h${this.cfgParam.maxXPx - this.cfgParam.minXPx}`} />
  }
  yaxis() {
    return <path className={this.styleClass.path} d={`M${this.cfgParam.minXPx} ${this.cfgParam.minYPx} v${this.cfgParam.maxYPx - this.cfgParam.minYPx}`} />
  }
  chartTitle() {
    return <text x={this.cfgParam.maxWidth / 2} y="40" className={this.styleClass.text_charttitle}>{this.cfgParam.name}: {this.cfgParam.startDate}</text>
  }
  value2y(value) {
    let result = this.cfgParam.minYPx - (value - this.cfgParam.minYValue) / (this.cfgParam.maxYValue - this.cfgParam.minYValue) * (this.cfgParam.minYPx - this.cfgParam.maxYPx)
    return result.toFixed(0)
    //return (cfgParam.maxHeight - ((extractTemp(event) / 40.0) * cfgParam.maxHeight))
  }
  
}

const DayMixin = (Base) => class extends Base {

  dayMinTime = 0
  dayMaxTime = 24
  dayChartTimes = [0, 3, 6, 9, 12, 15, 18, 21, 24]

  toXPixel(date) {
    let hours = date.getSeconds() / 60.0
    hours = (hours + date.getMinutes()) / 60.0
    hours = (hours + date.getHours())
    let result = this.cfgParam.minXPx - (hours - this.dayMinTime) / (this.dayMaxTime - this.dayMinTime) * (this.cfgParam.minXPx - this.cfgParam.maxXPx)
    return result.toFixed(0)
  }

  xAnnotate() {
    return [this.dayChartTimes.map((time) => {
      let date;
      if (time == 24) {
        date = new myTimeClass().endOfDay;
      } else {
        date = new Date(2020, 12, 1, time, 0, 0);
      }

      return <text className={this.styleClass.text_xaxis} x={this.toXPixel(date)} y={this.cfgParam.minYPx + 20}>{time}</text>

    }),
    <text x={this.cfgParam.maxXPx + 30} y={this.cfgParam.minYPx} className={this.styleClass.text_xaxis}>Zeit [h]</text>
    ]
  }

  xStrokes() {
    var list = [];
    for (let i = this.dayMinTime; i <= this.dayMaxTime; i++) {
      list.push(i)
    }
    return list.map((i) => {
      let date;
      if (i == 24) {
        date = new myTimeClass().endOfDay;
      } else {
        date = new Date(2020, 12, 1, i, 0, 0);
      }
      return <path className={this.styleClass.path} d={`M${this.toXPixel(date)},${this.cfgParam.minYPx} v-10`} />
    }
    );
  }

}

const WeekMixin = (Base) => class extends Base {

  weekMinTime = 0
  weekMaxTime = 7
  weekChartTimes = [0, 1, 2, 3, 4, 5, 6, 7]

  xAnnotate() {
    let weekdays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    let date = new myTimeClass().beginOfWeek.prevDay;
    return [this.weekChartTimes.map((dayidx) => {
      let dayname = weekdays[dayidx];
      date = date.nextDay;
      return <text className={this.styleClass.text_xaxis} x={this.toXPixel(date)} y={this.cfgParam.minYPx + 20}>{dayname}</text>
    }),
    <text x={this.cfgParam.maxXPx + 30} y={this.cfgParam.minYPx} className={this.styleClass.text_xaxis}>Zeit</text>
    ]
  }

  toXPixel(date) {
    let days = date.getSeconds() / 60.0
    days = (days + date.getMinutes()) / 60.0
    days = (days + date.getHours()) / 24.0
    days = days + date.getDay()
    let result = this.cfgParam.minXPx - (days - this.weekMinTime) / (this.weekMaxTime - this.weekMinTime) * (this.cfgParam.minXPx - this.cfgParam.maxXPx)
    return result.toFixed(0)
  }

  xStrokes() {
    let date = new myTimeClass().beginOfWeek;
    var list = [];
    for (let i = this.weekMinTime; i <= this.weekMaxTime; i++) {
      list.push(i)
    }
    return list.map((i) => {
      date = date.nextDay;
      return <path className={this.styleClass.path} d={`M${this.toXPixel(date)},${this.cfgParam.minYPx} v-10`} />
    }
    );
  }
}

const MonthMixin = (Base) => class extends Base {

  monthMinTime = 1
  monthMaxTime = 32
  monthChartTimes = [1, 8, 15, 22, 29]

  xAnnotate() {
    let date = new myTimeClass().beginOfMonth.prevWeek;
    return [this.monthChartTimes.map((weekidx) => {
      date = date.nextWeek;
      return <text className={this.styleClass.text_xaxis} x={this.toXPixel(date)} y={this.cfgParam.minYPx + 20}>{weekidx}</text>
    }),
    <text x={this.cfgParam.maxXPx + 30} y={this.cfgParam.minYPx} className={this.styleClass.text_xaxis}>Zeit</text>
    ]
  }

  toXPixel(date) {
    let days = date.getSeconds() / 60.0
    days = (days + date.getMinutes()) / 60.0
    days = (days + date.getHours()) / 24.0
    days = days + date.getDate()
  
    let result = this.cfgParam.minXPx - (days - this.monthMinTime) / (this.monthMaxTime - this.monthMinTime) * (this.cfgParam.minXPx - this.cfgParam.maxXPx)
    return result.toFixed(0)
  }

  xStrokes() {
    let date = new myTimeClass(new Date(2020, 12, 1, 1, 0, 0, 0)).beginOfMonth.prevDay;
    var list = [];
    for (let i = this.monthMinTime; i <= this.monthMaxTime; i++) {
      list.push(i)
    }
    return list.map((i) => {
      date = date.nextDay;
      return <path className={this.styleClass.path} d={`M${this.toXPixel(date)},${this.cfgParam.minYPx} v-10`} />
    }
    );
  }
}

const TemperatureMixin = (Base) => class extends Base {

  temperatureChartTemps = [0, 5, 10, 15, 20, 25, 30];

  drawCircles(cary) {
    return cary.map((evt) => {
      return <circle cx={this.toXPixel(new Date(TempData.extractTime(evt)))} cy={this.value2y(TempData.extractTemp(evt))} className={this.styleClass.circle_ds1}>
        <title>{TempData.extractTime(evt)}: {TempData.extractTemp(evt)} Celsius</title>
      </circle>
    })
  }

  yStrokes() {
    console.log("Min Value" + this.cfgParam.minYValue)
    console.log("Max Value" + this.cfgParam.maxYValue)
    var list = [];
    for (let i = this.cfgParam.minYValue; i <= this.cfgParam.maxYValue; i++) {
      list.push(i)
    }

    return list.map((i) =>
      <path className={this.styleClass.path} d={`M 0,${this.value2y(i)} H 10`} />
    );
  }
  
  yAnnotate() {
    return [this.temperatureChartTemps.map((temp) =>
      <text x="-25" y={this.value2y(temp)} className={this.styleClass.text_yaxis}>{temp}</text>
    ),
    <text x="-25" y={this.cfgParam.maxYPx - 15} className={this.styleClass.text_yaxis}>Temperatur[°C]</text>
    ]
  }
}

const MoistMixin = (Base) => class extends Base {

  moistChartMoists = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  drawCircles(cary) {
    return cary.map((evt) => {
      return <circle cx={this.toXPixel(new Date(MoistData.extractTime(evt)))} cy={this.value2y(MoistData.extractMoist(evt))} className={this.styleClass.circle_ds1}>
        <title>{MoistData.extractTime(evt)}: {MoistData.extractMoist(evt)} %</title>
      </circle>
    })
  }
  yStrokes() {
    var list = [];
    for (let i = this.cfgParam.minYValue; i <= this.cfgParam.maxYValue; i+=5) {
      list.push(i)
    }

    return list.map((i) =>
      <path className={this.styleClass.path} d={`M 0,${this.value2y(i)} H 10`} />
    );
  }
  yAnnotate() {
    return [this.moistChartMoists.map((value) =>
      <text x="-25" y={this.value2y(value)} className={this.styleClass.text_yaxis}>{value}</text>
    ),
    <text x="-25" y={this.cfgParam.maxYPx - 15} className={this.styleClass.text_yaxis}>Feuchtigkeit [%]</text>
    ]
  }
}

const SoilMoistMixin = (Base) => class extends Base {

  moistChartMoists = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  drawCircles(cary) {
    return cary.map((evt) => {
      return <circle cx={this.toXPixel(new Date(SoilMoistData.extractTime(evt)))} cy={this.value2y(SoilMoistData.extractMoist(evt))} className={this.styleClass.circle_ds1}>
        <title>{SoilMoistData.extractTime(evt)}: {SoilMoistData.extractMoist(evt)} %</title>
      </circle>
    })
  }
  yStrokes() {
    var list = [];
    for (let i = this.cfgParam.minYValue; i <= this.cfgParam.maxYValue; i+=5) {
      list.push(i)
    }

    return list.map((i) =>
      <path className={this.styleClass.path} d={`M 0,${this.value2y(i)} H 10`} />
    );
  }
  yAnnotate() {
    return [this.moistChartMoists.map((value) =>
      <text x="-25" y={this.value2y(value)} className={this.styleClass.text_yaxis}>{value}</text>
    ),
    <text x="-25" y={this.cfgParam.maxYPx - 15} className={this.styleClass.text_yaxis}>Feuchtigkeit [%]</text>
    ]
  }
}

const   DayTempChart = DayMixin(  TemperatureMixin(BaseChart))
const  WeekTempChart = WeekMixin( TemperatureMixin(BaseChart))
const MonthTempChart = MonthMixin(TemperatureMixin(BaseChart))

const   DayMoistChart = DayMixin(  MoistMixin(BaseChart))
const  WeekMoistChart = WeekMixin( MoistMixin(BaseChart))
const MonthMoistChart = MonthMixin(MoistMixin(BaseChart))

const   DaySoilMoistChart = DayMixin(  SoilMoistMixin(BaseChart))
const  WeekSoilMoistChart = WeekMixin( SoilMoistMixin(BaseChart))
const MonthSoilMoistChart = MonthMixin(SoilMoistMixin(BaseChart))



const TypeToChart = {
  [ThingType.TemperatureSensor]: {
    Tag: (cfgParam, styleClass) => { return new DayTempChart(cfgParam, styleClass) },
    Woche: (cfgParam, styleClass) => {return new WeekTempChart(cfgParam, styleClass) },
    Monat: (cfgParam, styleClass) => {return new MonthTempChart(cfgParam, styleClass) }
  },
  [ThingType.HumiditySensor]: {
    Tag: (cfgParam, styleClass) => { return new DayMoistChart(cfgParam, styleClass) },
    Woche: (cfgParam, styleClass) => { return new WeekMoistChart(cfgParam, styleClass) },
    Monat: (cfgParam, styleClass) => { return new MonthMoistChart(cfgParam, styleClass) }
  },
  [ThingType.SoilMoistureSensor]: {
    Tag: (cfgParam, styleClass) => { return new DaySoilMoistChart(cfgParam, styleClass) },
    Woche: (cfgParam, styleClass) => { return new WeekSoilMoistChart(cfgParam, styleClass) },
    Monat: (cfgParam, styleClass) => { return new MonthSoilMoistChart(cfgParam, styleClass) }
  }
}

function MyChart({ cfgParam, data }) {

  const classes = useStyles();

  function addValues(chart) {
    let tary = data.eventList()
    //for (let evt of tary) { console.log(evt) }
    return chart.drawCircles(tary)
  }

  function initChart(chart) {
    // alert("ich bin hier 1")
    return [...chart.yAnnotate(), ...chart.xAnnotate(), ...chart.xStrokes(), ...chart.yStrokes(), chart.chartTitle(), chart.xaxis(), chart.yaxis()];
  }

  function init() {
    console.log("cfgParam: " + cfgParam.type + " " + cfgParam.yType)
    const chart_create = TypeToChart[cfgParam.yType][cfgParam.type]
    if (chart_create) {
      let chart = chart_create(cfgParam, classes)
      return [...initChart(chart), ...addValues(chart)]
    } else {
      console.log("Problem")
    }
  }

  return init();
}
