import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import {
  Container,
  CircularProgress,
  Toolbar,
  IconButton,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  Backdrop,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useParams, Link } from 'react-router-dom';

import { FormikErrors, Formik } from 'formik';
import {
  Thing,
  ListThingsData,
  LIST_THING_QUERY,
  DeleteRoomData,
  DeleteRoomInput,
  DELETE_ROOM_MUTATION,
  RenameRoomData,
  RenameRoomInput,
  RENAME_ROOM_MUTATION,
  extendedThingType,
} from '../../api/things';
import * as Constants from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertMargin: {
      marginTop: '5%',
    },
    container: {
      paddingBottom: theme.spacing(4),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

type RenameRoomUserInput = {
  name: string;
};

export default function RoomSettings() {
  const classes = useStyles();

  const history = useHistory();
  const historyGoSettings = () => history.push('/app/settings');
  const historyGoNewRoom = (newName: string) =>
    history.push(`/settings/room-settings/${encodeURIComponent(newName)}`);

  const { roomName }: { roomName?: string } = useParams();
  console.log('Room Settings for Room:', roomName);
  if (roomName === undefined) historyGoSettings();

  const { loading, error, data, refetch, networkStatus } = useQuery<ListThingsData, undefined>(
    LIST_THING_QUERY
  );
  if (error) console.log(JSON.stringify(error));
  if (data) console.log(data);

  const roomThings = data?.listThings.reduce((things: Thing[], thing) => {
    if ((thing.room || Constants.OtherDevices) === roomName) things.push(thing);
    return things;
  }, []);

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  function handleCloseDeleteDialog() {
    setDeleteDialogOpen(false);
  }
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  function handleCloseRenameDialog() {
    setRenameDialogOpen(false);
  }

  const [refetchTriggered, setRefetchTriggered] = React.useState(false);

  const [deleteRoom, { error: deleteRoomError, loading: deleteRoomLoading }] = useMutation<
    DeleteRoomData,
    DeleteRoomInput
  >(DELETE_ROOM_MUTATION);

  const deleteRoomClicked = async () => {
    if (roomName === undefined) return;
    try {
      setRefetchTriggered(false);
      await deleteRoom({
        variables: {
          name: roomName,
        },
      });
      historyGoSettings();
    } catch (err) {
      console.log(err);
      handleCloseDeleteDialog();
    }
  };

  const [renameRoom, { error: renameRoomError, loading: renameRoomLoading }] = useMutation<
    RenameRoomData,
    RenameRoomInput
  >(RENAME_ROOM_MUTATION);

  const renameValidate = (input: RenameRoomUserInput) => {
    const errors: FormikErrors<RenameRoomUserInput> = {};
    if (input.name === '') errors.name = 'Neuen Gerätename eingeben.';
    else if (input.name.length > 50) errors.name = 'Maximal 50 Zeichen.';
    else if (!Constants.LetterNumberSpaceRegex.test(input.name))
      errors.name = 'Nur Buchstaben, Zahlen und Leerzeichen zulässig.';
    return errors;
  };

  const renameClicked = async (input: RenameRoomUserInput) => {
    if (roomName === undefined) return;
    try {
      setRefetchTriggered(false);
      await renameRoom({
        variables: {
          oldName: roomName,
          newName: input.name,
        },
      });
      historyGoNewRoom(input.name);
    } catch (err) {
      console.log(err);
    }
    handleCloseRenameDialog();
  };

  async function triggerRefetch() {
    console.log('refetching scan data');
    try {
      setRefetchTriggered(true);
      await refetch();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={historyGoSettings} aria-label="close">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {roomName === Constants.OtherDevices ? Constants.OtherDevices : 'Raum Einstellungen'}
        </Typography>
      </Toolbar>
      <Container maxWidth="lg" className={classes.container}>
        {(loading || networkStatus === NetworkStatus.refetch) && (
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={classes.alertMargin}
          >
            <CircularProgress />
          </div>
        )}
        <Backdrop className={classes.backdrop} open={deleteRoomLoading || renameRoomLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {(error || ((deleteRoomError || renameRoomError) && !refetchTriggered)) && (
          <Alert
            severity="error"
            className={classes.alertMargin}
            action={
              <Button color="inherit" size="small" onClick={triggerRefetch}>
                Retry
              </Button>
            }
          >
            <AlertTitle>Error</AlertTitle>
            {error?.message ?? deleteRoomError?.message}
          </Alert>
        )}
        {roomThings && (
          <>
            {roomName !== Constants.OtherDevices && (
              <>
                <List
                  subheader={
                    <ListSubheader color="primary" disableSticky>
                      Allgemein
                    </ListSubheader>
                  }
                >
                  <ListItem button>
                    <ListItemText
                      primary="Name"
                      secondary={roomName}
                      onClick={() => setRenameDialogOpen(true)}
                    />
                  </ListItem>
                </List>
                <Divider />
              </>
            )}
            <List
              subheader={
                <ListSubheader color="primary" disableSticky>
                  {roomName !== Constants.OtherDevices && 'Geräte'}
                </ListSubheader>
              }
            >
              {roomThings.length === 0 && (
                <Alert severity="info">
                  {roomName === Constants.OtherDevices
                    ? 'Alle angelernten Geräte sind bereits einem Raum zugeortned.'
                    : 'Sie haben noch keine Geräte diesem Raum hinzugefügt.'}
                </Alert>
              )}
              {roomThings.length > 0 &&
                roomThings.map((thing) => (
                  <ListItem
                    button
                    key={thing.id}
                    component={Link}
                    to={`/settings/thing-settings/${encodeURIComponent(thing.id)}`}
                  >
                    <ListItemText primary={thing.name} secondary={extendedThingType(thing)} />
                  </ListItem>
                ))}
            </List>
            {roomName && roomName !== Constants.OtherDevices && (
              <>
                <Divider />
                <List>
                  <ListItem button>
                    <ListItemText primary="Geräte hinzufügen" />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem button onClick={() => setDeleteDialogOpen(true)}>
                    <ListItemText primary="Raum löschen" />
                  </ListItem>
                </List>
                <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
                  <DialogTitle>Raum {roomName} wirklich löschen</DialogTitle>
                  <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                      Abbruch
                    </Button>
                    <Button onClick={deleteRoomClicked} color="primary">
                      Löschen
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={renameDialogOpen} onClose={handleCloseRenameDialog}>
                  <Formik
                    initialValues={{ name: roomName }}
                    onSubmit={renameClicked}
                    validate={renameValidate}
                  >
                    {({ values, errors, touched, handleSubmit, handleChange }) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <DialogTitle>Gerät umbenennen</DialogTitle>
                        <DialogContent>
                          <TextField
                            autoFocus
                            fullWidth
                            required
                            margin="normal"
                            name="name"
                            label="Neuer Raumname"
                            autoComplete="off"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name !== undefined && touched.name}
                            helperText={errors.name && touched.name && errors.name}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseRenameDialog} color="primary">
                            Abbruch
                          </Button>
                          <Button type="submit" color="primary">
                            Speichern
                          </Button>
                        </DialogActions>
                      </form>
                    )}
                  </Formik>
                </Dialog>
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
}
