import React from 'react';
import { Route, Redirect, Switch, RouteChildrenProps } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import TimelineIcon from '@material-ui/icons/Timeline';
import GavelIcon from '@material-ui/icons/Gavel';
import SettingsIcon from '@material-ui/icons/Settings';
import Home from './Home';
import Statistics from './Statistics';
import Automation from './Automation';
import Settings from './Settings';
import BottomNav from './BottomNav';
import CustomAppBar from '../layout/CustomAppBar';

export const PathMap = [
  { path: '/app/home', title: 'Homeapp', component: Home, bottomIcon: <HomeIcon /> },
  {
    path: '/app/statistics',
    title: 'Statistik',
    component: Statistics,
    bottomIcon: <TimelineIcon />,
  },
  {
    path: '/app/automation',
    title: 'Automatisierung',
    component: Automation,
    bottomIcon: <GavelIcon />,
  },
  {
    path: '/app/settings',
    title: 'Einstellungen',
    component: Settings,
    bottomIcon: <SettingsIcon />,
  },
];

export const PathToIndex = (path: string) =>
  Math.max(
    0,
    PathMap.findIndex((item) => path.startsWith(item.path))
  );

const HomeLayout = ({ location }: RouteChildrenProps) => (
  <>
    <CustomAppBar title={PathMap[PathToIndex(location.pathname)].title} />
    <Switch>
      {PathMap.map((page) => (
        <Route path={page.path} component={page.component} key={page.path} />
      ))}
      <Redirect from="*" to={PathMap[0].path} />
    </Switch>
    <BottomNav path={location.pathname} />
  </>
);
export default HomeLayout;
