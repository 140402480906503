import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Step,
  Stepper,
  Typography,
  Container,
  StepButton,
  Theme,
  LinearProgress,
} from '@material-ui/core';
import { FormikErrors } from 'formik';
import { useMutation } from '@apollo/client';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router';
import SetLocation, { HouseLocation } from './SetLocation';
import SetHouseName, { HouseName } from './SetHouseName';
import * as Constants from '../../utils/constants';
import { SetupGeneralData, SetupGeneralInput, SETUP_GENERAL_MUTATION } from '../../api/general';

type StepperObjectT = {
  label: string;
  content: JSX.Element;
  validate: () => boolean;
};

export type StepperObjectContentProps<T> = {
  values: T;
  errors: FormikErrors<T>;
  handleChange: (newVal: T) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    marginTop: '10%',
  },
  contentDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  stepButtonCursor: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  stepButtonsDiv: {
    marginLeft: '5%',
  },
  loadingError: {
    marginTop: '5%',
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export default function GeneralSetup() {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);

  const [setupGeneral, { error: setupGeneralError, loading: setupGeneralLoading }] = useMutation<
    SetupGeneralData,
    SetupGeneralInput
  >(SETUP_GENERAL_MUTATION);

  const history = useHistory();
  const historyGoHome = () => history.push('/app/home');

  const [houseNameValues, sethouseNameValues] = useState<HouseName>({ houseName: '' });
  const [houseNameErrors, sethouseNameErrors] = useState<FormikErrors<HouseName>>({});

  const [locationValues, setlocationValues] = useState<HouseLocation>({
    latitude: 0,
    longitude: 0,
  });
  const [locationErrors, setlocationErrors] = useState<FormikErrors<HouseLocation>>({});

  const steps: StepperObjectT[] = [
    {
      label: 'Hausname wählen',
      content: (
        <SetHouseName
          values={houseNameValues}
          errors={houseNameErrors}
          handleChange={sethouseNameValues}
        />
      ),
      validate: () => {
        const errors: FormikErrors<HouseName> = {};
        if (houseNameValues.houseName === '') errors.houseName = 'Hausname eingeben.';
        else if (houseNameValues.houseName.length > 50) errors.houseName = 'Maximal 50 Zeichen.';
        else if (!Constants.LetterNumberSpaceRegex.test(houseNameValues.houseName))
          errors.houseName = 'Nur Buchstaben, Zahlen und Leerzeichen zulässig.';
        sethouseNameErrors(errors);
        return Object.keys(errors).length === 0;
      },
    },
    {
      label: 'Standort setzen',
      content: (
        <SetLocation
          values={locationValues}
          errors={locationErrors}
          handleChange={setlocationValues}
        />
      ),
      validate: () => {
        const errors: FormikErrors<HouseLocation> = {};
        if (!Number.isFinite(locationValues.latitude) || Math.abs(locationValues.latitude) > 90)
          errors.latitude = 'Zahl zwischen -90 und 90.';
        if (!Number.isFinite(locationValues.longitude) || Math.abs(locationValues.longitude) > 180)
          errors.longitude = 'Zahl zwischen -180 und 180.';
        setlocationErrors(errors);
        return Object.keys(errors).length === 0;
      },
    },
  ];

  const handleNext = async () => {
    if (steps[activeStep].validate()) {
      if (activeStep >= steps.length - 1)
        try {
          await setupGeneral({
            variables: {
              houseName: houseNameValues.houseName,
              latitude: locationValues.latitude,
              longitude: locationValues.longitude,
            },
          });
          // historyGoHome();
          window.location.reload(); // TODO fix
        } catch (e) {
          console.log('setup bridge error:', e);
        }
      else setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  return (
    <Container className={classes.mainContainer} component="main" maxWidth="md">
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          Bridge Setup
        </Typography>
        <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepButton className={classes.stepButtonCursor}>{step.label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {setupGeneralError && (
          <Alert severity="error" className={classes.loadingError}>
            <AlertTitle>Setup Bridge Error</AlertTitle>
            {setupGeneralError.message}
          </Alert>
        )}
        {setupGeneralLoading && <LinearProgress />}
        <div className={classes.contentDiv}>{steps[activeStep].content}</div>
        <div className={classes.stepButtonsDiv}>
          <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
            Zurück
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
          >
            {activeStep === steps.length - 1 ? 'Fertigstellen' : 'Weiter'}
          </Button>
        </div>
      </Paper>
    </Container>
  );
}
