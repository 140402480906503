import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NewAutomation from './New';
import EditAutomation from './Edit';

const AutomationLayout = () => (
  <Switch>
    <Route path="/automation/new" component={NewAutomation} />
    <Route path="/automation/edit/:automationId" component={EditAutomation} />
    <Redirect from="*" to="/app/automation" />
  </Switch>
);
export default AutomationLayout;
