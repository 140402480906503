import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import AutomationCreateUpdate from './AutomationCreateUpdate';
import {
  AutomationRuleServerInput,
  EditAutomationData,
  EditAutomationInput,
  EDIT_AUTOMATION_MUTATION,
  GetAutomationData,
  GetAutomationInput,
  GET_AUTOMATION_QUERY,
} from '../../api/automation';

export default function EditAutomation() {
  const { automationId } = useParams<{ automationId: string }>();
  console.log('Automation Settings for Automation:', automationId);

  const history = useHistory();
  const historyGoAutomation = () => history.push('/app/automation');

  const {
    loading: getAutomationLoading,
    error: getAutomationError,
    data: getAutomationData,
  } = useQuery<GetAutomationData, GetAutomationInput>(GET_AUTOMATION_QUERY, {
    variables: { id: automationId },
    fetchPolicy: 'network-only',
  });
  if (getAutomationError) console.log(JSON.stringify(getAutomationError));
  if (getAutomationData) console.log(getAutomationData);

  const [
    editAutomation,
    { error: editAutomationError, loading: editAutomationLoading },
  ] = useMutation<EditAutomationData, EditAutomationInput>(EDIT_AUTOMATION_MUTATION);

  const handleEditAutomation = async (rule: AutomationRuleServerInput) => {
    try {
      await editAutomation({ variables: { id: automationId, rule } });
    } catch (err) {
      return;
    }
    historyGoAutomation();
  };

  return (
    <AutomationCreateUpdate
      defaultValue={getAutomationData?.getDetailedAutomation}
      hideForm={
        getAutomationData === undefined || getAutomationError !== undefined || getAutomationLoading
      }
      gqlError={getAutomationError ?? editAutomationError}
      gqlLoading={getAutomationLoading || editAutomationLoading}
      onSubmit={handleEditAutomation}
    />
  );
}
