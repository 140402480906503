import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CustomAppBar from '../layout/CustomAppBar';
import OAuth2Authorize from './Authorize';

const AutomationLayout = () => (
  <>
    <CustomAppBar title="Account Linking" />
    <Switch>
      <Route path="/oauth2/authorize" component={OAuth2Authorize} />
      <Redirect from="*" to="/app/home" />
    </Switch>
  </>
);
export default AutomationLayout;
