import React from 'react';
import { Thing, ThingType } from '../../api/things';
import ThingShutter from './shutter';
import ThingLight from './light';
import ThingIrrigation from './irrigation';
import ThingVentilation from './ventilation';
import ThingSoilMoistureSensor from './soilMoistureSensor';
import ThingHumiditySensor from './humiditySensor';
import ThingTemperatureSensor from './temperatureSensor';
import ThingPressureSensor from './pressureSensor';
import ThingWaterLevelSensor from './waterLevelSensor';

export type ThingCardProps = {
  thing: Thing;
};

const ThingCard = ({ thing }: ThingCardProps) => {
  // console.log(thing);

  switch (thing.type) {
    case ThingType.Light:
      return <ThingLight thing={thing} />;
    case ThingType.Shutter:
      return <ThingShutter thing={thing} />;
    case ThingType.Ventilation:
      return <ThingVentilation thing={thing} />;
    case ThingType.Irrigation:
      return <ThingIrrigation thing={thing} />;
    case ThingType.SoilMoistureSensor:
      return <ThingSoilMoistureSensor thing={thing} />;
    case ThingType.HumiditySensor:
      return <ThingHumiditySensor thing={thing} />;
    case ThingType.TemperatureSensor:
      return <ThingTemperatureSensor thing={thing} />;
    case ThingType.PressureSensor:
      return <ThingPressureSensor thing={thing} />;
    case ThingType.WaterLevelSensor:
      return <ThingWaterLevelSensor thing={thing} />;
    default:
      console.log('thing type not supported yet!');
      return <></>;
  }
};

export default ThingCard;
