import React, { useState } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import { Formik, FormikErrors } from 'formik';
import Alert from '@material-ui/lab/Alert';
import authStyles from './authStyles';
import { SignInData } from '../../api/auth';
import { useAuthCtx } from '../../context/auth';
import PasswordField from './PasswordField';

interface LocationState {
  from: {
    pathname: string;
  };
}

const SignIn = () => {
  const classes = authStyles();

  const { signIn, isAuthenticated } = useAuthCtx();
  const [submitErrorState, setSubmitErrorState] = useState('');

  const location = useLocation<LocationState>();
  const from = location.state?.from?.pathname ?? '/app/home';
  // const from = '/app/home';
  console.log('from is:', from);
  const [redirectToReferrer, setredirectToReferrer] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason !== 'clickaway') setSubmitErrorState('');
  };

  const submitSignInData = async (data: SignInData) => {
    console.log('Submit', data);
    const errorMsg = await signIn(data);
    console.log('SignIn performed:', errorMsg === '' ? true : errorMsg);
    if (errorMsg !== '') setSubmitErrorState(`signin error: ${errorMsg}`);
    else setredirectToReferrer(true);
  };

  const validate = (data: SignInData) => {
    const errors: FormikErrors<SignInData> = {};
    if (data.email === '') errors.email = 'E-Mail-Adresse eingeben';
    if (data.password === '') errors.password = 'Passwort eingeben';
    return errors;
  };

  // if (redirectToReferrer) return <Redirect to={from} />;
  if (isAuthenticated) return <Redirect to={from} />;

  return (
    <Container className={classes.mainContainer} component="main" maxWidth="xs">
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Anmelden
      </Typography>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={submitSignInData}
        validate={validate}
      >
        {({ values, errors, touched, isSubmitting, handleSubmit, handleChange }) => (
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <Backdrop className={classes.backdrop} open={isSubmitting || redirectToReferrer}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={submitErrorState !== ''} onClose={handleClose}>
              <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
                {submitErrorState}
              </Alert>
            </Snackbar>

            <TextField
              autoFocus
              fullWidth
              required
              margin="normal"
              variant="outlined"
              label="E-Mail-Adresse"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleChange}
              // onBlur={handleBlur}
              error={errors.email !== undefined && touched.email}
              helperText={errors.email && touched.email && errors.email}
            />
            <PasswordField
              fullWidth
              required
              margin="normal"
              variant="outlined"
              name="password"
              label="Passwort"
              type="password"
              autoComplete="current-password"
              value={values.password}
              onChange={handleChange}
              // onBlur={handleBlur}
              error={errors.password !== undefined && touched.password}
              helperText={errors.password && touched.password && errors.password}
            />
            <Link className={classes.forgotPassword} to="/about">
              Passwort vergessen?
            </Link>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Anmelden
            </Button>
          </form>
        )}
      </Formik>
      <Link className={classes.signInUpLink} to="/auth/signup">
        Noch nicht registriert? Jetzt registrieren
      </Link>
      <Box mt={5} className={classes.copyrightBox}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link className={classes.copyrightLink} to="/policies/copyright">
            Homeapp Gessler
          </Link>{' '}
          {new Date().getFullYear()}.
        </Typography>
      </Box>
    </Container>
  );
};

export default SignIn;
