import React, { useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Avatar,
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Formik, FormikErrors } from 'formik';
import authStyles from './authStyles';
import { SignUpData } from '../../api/auth';
import PasswordField from './PasswordField';
import { useAuthCtx } from '../../context/auth';

interface LocationState {
  from: {
    pathname: string;
  };
}

const SignUp = () => {
  const classes = authStyles();

  const { signUp, isAuthenticated } = useAuthCtx();
  const [submitErrorState, setSubmitErrorState] = React.useState('');

  const location = useLocation<LocationState>();
  const from = location.state?.from?.pathname ?? '/app/home';
  console.log('from is:', from);
  // const from = '/app/home';
  const [redirectToReferrer, setredirectToReferrer] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason !== 'clickaway') setSubmitErrorState('');
  };

  const submitSignUpData = async (data: SignUpData) => {
    console.log('Submit', data);
    const errorMsg = await signUp(data);
    console.log('SignUp performed:', errorMsg === '' ? true : errorMsg);
    if (errorMsg !== '') setSubmitErrorState(`signUp error: ${errorMsg}`);
    else setredirectToReferrer(true);
  };

  const validate = (data: SignUpData) => {
    const errors: FormikErrors<SignUpData> = {};
    if (data.bridgeId === '') errors.bridgeId = 'Name des Zuhause eingeben';
    else if (data.bridgeId.length > 50) errors.bridgeId = 'Maximal 50 Zeichen';
    // else if (!/^[a-zA-Z0-9]+$/.test(data.bridgeId))
    //   errors.bridgeId = 'Nur Buchstaben und Zahlen zulässig';
    if (data.email === '') errors.email = 'Email darf nicht leer sein';
    else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email))
      errors.email = 'Ungültige E-mail-Adresse';
    if (data.password === '') errors.password = 'Passwort eingeben';
    else if (data.password.length < 8 || data.password.length > 30)
      errors.password = 'Passwort muss zwischen 8 und 30 Zeichen enthalten';
    else if (!/^[a-zA-Z0-9 !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/.test(data.password))
      errors.password = 'Sie können nur Buchstaben, Zahlen und gängige Satzzeichen verwenden';
    else if (!/(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])/.test(data.password))
      errors.password = 'Das Passwort muss Buchstaben, Ziffern und Symbolen enthalten';
    return errors;
  };

  const passwordDefaultHelperText =
    '8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Ziffern und Symbolen verwenden';

  // if (redirectToReferrer) return <Redirect to={from} />;
  if (isAuthenticated) return <Redirect to={from} />;

  return (
    <Container className={classes.mainContainer} component="main" maxWidth="xs">
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Registrieren
      </Typography>
      <Formik
        initialValues={{ email: '', password: '', bridgeId: '' }}
        onSubmit={submitSignUpData}
        validate={validate}
      >
        {({ values, errors, touched, isSubmitting, handleSubmit, handleChange }) => (
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <Backdrop className={classes.backdrop} open={isSubmitting || redirectToReferrer}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={submitErrorState !== ''} onClose={handleClose}>
              <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
                {submitErrorState}
              </Alert>
            </Snackbar>

            <TextField
              autoFocus
              fullWidth
              required
              margin="normal"
              variant="outlined"
              name="bridgeId"
              label="Bridge Id"
              autoComplete="nope"
              value={values.bridgeId}
              onChange={handleChange}
              // onBlur={handleBlur}
              error={errors.bridgeId !== undefined && touched.bridgeId}
              helperText={errors.bridgeId && touched.bridgeId && errors.bridgeId}
            />
            <TextField
              fullWidth
              required
              margin="normal"
              variant="outlined"
              label="E-Mail-Adresse"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleChange}
              // onBlur={handleBlur}
              error={errors.email !== undefined && touched.email}
              helperText={errors.email && touched.email && errors.email}
            />
            <PasswordField
              fullWidth
              required
              margin="normal"
              variant="outlined"
              name="password"
              label="Passwort"
              autoComplete="new-password"
              value={values.password}
              onChange={handleChange}
              // onBlur={handleBlur}
              error={errors.password !== undefined && touched.password}
              helperText={
                errors.password && touched.password
                  ? errors.password
                  : !touched.password && passwordDefaultHelperText
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Registrieren
            </Button>
          </form>
        )}
      </Formik>
      <Link className={classes.signInUpLink} to="/auth/signin">
        Schon Registriert? Hier Einloggen
      </Link>
      <Box mt={5} className={classes.copyrightBox}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link className={classes.copyrightLink} to="/policies/copyright">
            Homeapp Gessler
          </Link>{' '}
          {new Date().getFullYear()}.
        </Typography>
      </Box>
    </Container>
  );
};
export default SignUp;
