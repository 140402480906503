import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import SignUp from './SignUp';
import SignIn from './SignIn';

const AuthLayout = () => (
  <div>
    <Switch>
      <Route path="/auth/signup" component={SignUp} />
      <Route path="/auth/signin" component={SignIn} />
      <Redirect path="/auth" to="/auth/signup" />
    </Switch>
  </div>
);
export default AuthLayout;
