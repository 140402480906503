import React from 'react';
import {
  makeStyles,
  createStyles,
  Card,
  CardHeader,
  Switch,
  CardActions,
  Slider,
} from '@material-ui/core';
import ToysIcon from '@material-ui/icons/Toys';
import { useMutation } from '@apollo/client';
import { ThingCardProps } from './thing';
import { PerformActionData, PERFORM_ACTION_MUTATION, PerformActionInput } from '../../api/action';
import { ThingTrait } from '../../api/things';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 100,
      minHeight: 110,
    },
    slider: {
      marginLeft: 15,
      marginRight: 15,
      width: 220,
    },
    cardHeader: {
      marginBottom: -20,
    },
  })
);

export default function ThingVentilation({ thing }: ThingCardProps) {
  const classes = useStyles();

  const [performAction, { error }] = useMutation<PerformActionData, PerformActionInput>(
    PERFORM_ACTION_MUTATION
  );
  if (error) console.log(JSON.stringify(error));

  async function handleSliderChange(event: React.ChangeEvent<{}>, newVal: number | number[]) {
    try {
      const res = await performAction({
        variables: {
          action: {
            thingId: thing.id,
            trait: ThingTrait.FanSpeed,
            input: {
              fanSpeed: newVal,
            },
          },
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>) {
    try {
      const res = await performAction({
        variables: {
          action: {
            thingId: thing.id,
            trait: ThingTrait.OnOff,
            input: {
              on: event.target.checked,
            },
          },
        },
      });
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  const { maxFanSpeed, fanSpeed: sliderVal, on: switchState } = thing.properties;
  if (
    !thing.availableActions.includes(ThingTrait.OnOff) ||
    typeof switchState !== 'boolean' ||
    !thing.availableActions.includes(ThingTrait.FanSpeed) ||
    typeof sliderVal !== 'number' ||
    !Number.isInteger(sliderVal) ||
    sliderVal <= 0 ||
    typeof maxFanSpeed !== 'number' ||
    !Number.isInteger(maxFanSpeed) ||
    sliderVal > maxFanSpeed
  ) {
    console.log('invalid ventilation:', thing);
    return <></>;
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<ToysIcon />}
        action={
          sliderVal && (
            <Switch checked={switchState} color="primary" onChange={handleSwitchChange} />
          )
        }
        className={classes.cardHeader}
        title={thing.name}
      />
      <CardActions>
        {sliderVal && (
          <Slider
            className={classes.slider}
            value={sliderVal}
            onChangeCommitted={handleSliderChange}
            step={1}
            min={1}
            max={maxFanSpeed}
            valueLabelDisplay="auto"
            marks={Array.from(Array(maxFanSpeed), (_, i) => {
              return { value: i + 1, label: `Stufe ${i + 1}` };
            })}
          />
        )}
      </CardActions>
    </Card>
  );
}
