import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import * as Constants from '../../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(4),
    },
    alertMargin: {
      marginTop: '5%',
    },
  })
);

function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function OAuth2Authorize() {
  const classes = useStyles();
  const query = useUrlQuery();

  const clientId = query.get('client_id');
  const responseType = query.get('response_type');
  const redirectUri = query.get('redirect_uri');
  const state = query.get('state');

  const error = !clientId || !responseType || !redirectUri || !state;
  console.log(
    `oauth2 error: ${error} - params: redirectUri: ${redirectUri}, state: ${state}, responseType: ${responseType}, clientId${clientId}`
  );

  const oaut2Endpoint = `${Constants.OAuthAuthorizeUrl}?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&state=${state}`;

  useEffect(() => {
    if (!error) window.location.href = oaut2Endpoint;
  }, [oaut2Endpoint, error]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      {error ? (
        <Alert severity="error" className={classes.alertMargin}>
          <AlertTitle>Fehlerhafte Anfrage</AlertTitle>
          Ungültige OAuth2 Anfrage bitte erneut versuchen!
        </Alert>
      ) : (
        <Alert severity="info" className={classes.alertMargin}>
          <AlertTitle>Redirecting...</AlertTitle>
          You will be redirected shortly
        </Alert>
      )}
    </Container>
  );
}
