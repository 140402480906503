import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import {
  TriggerType,
  TimeTriggerServer,
  SunTriggerServer,
  TriggerRecurrence,
  AutomationTriggerServer,
  TriggerRecurrenceToString,
  TriggerWeekdayMap,
  TriggerWeekdayToShortString,
} from '../../api/automation';
import { Thing } from '../../api/things';

type AutomationTriggerHelper = {
  type: TriggerType;
  time: TimeTriggerServer;
  sun: SunTriggerServer;
};

function getDefaultTriggerDialogInput(): AutomationTriggerHelper {
  return {
    type: TriggerType.Time,
    time: {
      recurrence: TriggerRecurrence.Dayly,
      weekdays: {
        monday: true,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      time: new Date(),
    },
    sun: {
      sunrise: true,
      offsetMin: 0,
    },
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(4),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    headingAccordion: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
    },
    cardAutomation: {
      marginTop: 16,
    },
    dialogSelectMargin: {
      marginTop: 10,
    },
    loadingIndicator: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '5%',
    },
  })
);

type TriggerHelperProps = {
  dialogOpen: boolean;
  handleCloseDialog: () => void;
  addTrigger: (trigger: AutomationTriggerServer) => void;
  availableThings: Thing[];
  handleError: () => void;
};

export default function TriggerHelper({
  dialogOpen,
  handleCloseDialog,
  addTrigger,
}: TriggerHelperProps) {
  const classes = useStyles();

  const [triggerDialogInputState, setTriggerDialogInputState] = useState<AutomationTriggerHelper>(
    getDefaultTriggerDialogInput
  );
  const handleTriggerTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTriggerDialogInputState({
      ...triggerDialogInputState,
      type: event.target.value as TriggerType,
    });
  };
  const handleTriggerTimeChange = (date: Date | null) => {
    if (date === null) return;
    setTriggerDialogInputState({
      ...triggerDialogInputState,
      time: { ...triggerDialogInputState.time, time: date },
    });
  };
  const handleTriggerRecurrenceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTriggerDialogInputState({
      ...triggerDialogInputState,
      time: {
        ...triggerDialogInputState.time,
        recurrence: event.target.value as TriggerRecurrence,
      },
    });
  };
  const handleTriggerWeekdayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      !Object.entries(triggerDialogInputState.time.weekdays).some(([key, value]) => {
        if (key === event.target.name) return event.target.checked;
        return value;
      })
    )
      return;
    const newWeekdayMap: TriggerWeekdayMap = triggerDialogInputState.time.weekdays;
    newWeekdayMap[event.target.name as keyof TriggerWeekdayMap] = event.target.checked;
    setTriggerDialogInputState({
      ...triggerDialogInputState,
      time: {
        ...triggerDialogInputState.time,
        weekdays: newWeekdayMap,
      },
    });
  };
  const handleTriggerSunriseChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTriggerDialogInputState({
      ...triggerDialogInputState,
      sun: {
        ...triggerDialogInputState.sun,
        sunrise: event.target.value === 'true',
      },
    });
  };
  const handleTriggerSunOffsetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTriggerDialogInputState({
      ...triggerDialogInputState,
      sun: {
        ...triggerDialogInputState.sun,
        offsetMin: event.target.value as number,
      },
    });
  };

  const handleAddTriggerClicked = () => {
    setTriggerDialogInputState(getDefaultTriggerDialogInput);
    addTrigger(triggerDialogInputState); // no validation needed at the moment
  };

  return (
    <Dialog open={dialogOpen} onClose={handleCloseDialog}>
      <DialogTitle>Triger hinzufügen</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Typ</InputLabel>
          <Select value={triggerDialogInputState.type} onChange={handleTriggerTypeChange}>
            <MenuItem value={TriggerType.Time}>Zeit</MenuItem>
            {/* <MenuItem value={TriggerType.Device}>Gerät</MenuItem> */}
            <MenuItem value={TriggerType.Sun}>Sonne</MenuItem>
          </Select>
        </FormControl>
        {triggerDialogInputState.type === TriggerType.Time && (
          <>
            <FormControl fullWidth className={classes.dialogSelectMargin}>
              <InputLabel>Wiederholung</InputLabel>
              <Select
                value={triggerDialogInputState.time.recurrence}
                onChange={handleTriggerRecurrenceChange}
              >
                <MenuItem value={TriggerRecurrence.Minutely}>
                  {TriggerRecurrenceToString(TriggerRecurrence.Minutely)}
                </MenuItem>
                <MenuItem value={TriggerRecurrence.Hourly}>
                  {TriggerRecurrenceToString(TriggerRecurrence.Hourly)}
                </MenuItem>
                <MenuItem value={TriggerRecurrence.Dayly}>
                  {TriggerRecurrenceToString(TriggerRecurrence.Dayly)}
                </MenuItem>
                <MenuItem value={TriggerRecurrence.Weekly}>
                  {TriggerRecurrenceToString(TriggerRecurrence.Weekly)}
                </MenuItem>
              </Select>
            </FormControl>
            {(triggerDialogInputState.time.recurrence === TriggerRecurrence.Weekly ||
              triggerDialogInputState.time.recurrence === TriggerRecurrence.Dayly) && (
              <TimePicker
                fullWidth
                showTodayButton
                todayLabel="jetzt"
                cancelLabel="Abbruch"
                // autoOk
                margin="normal"
                label="Zeit auswählen"
                ampm={false}
                value={triggerDialogInputState.time.time}
                onChange={handleTriggerTimeChange}
              />
            )}
            {triggerDialogInputState.time.recurrence === TriggerRecurrence.Weekly && (
              <div className={classes.dialogSelectMargin}>
                {Object.keys(triggerDialogInputState.time.weekdays).map((day) => (
                  <FormControlLabel
                    key={day}
                    control={
                      <Checkbox
                        color="primary"
                        onChange={handleTriggerWeekdayChange}
                        checked={
                          triggerDialogInputState.time.weekdays[day as keyof TriggerWeekdayMap]
                        }
                      />
                    }
                    label={TriggerWeekdayToShortString(day)}
                    name={day}
                    labelPlacement="bottom"
                  />
                ))}
              </div>
            )}
          </>
        )}
        {triggerDialogInputState.type === TriggerType.Sun && (
          <>
            <FormControl fullWidth className={classes.dialogSelectMargin}>
              <InputLabel>Art</InputLabel>
              <Select
                value={triggerDialogInputState.sun.sunrise}
                onChange={handleTriggerSunriseChange}
              >
                <MenuItem value="true">Sonnenaufgang</MenuItem>
                <MenuItem value="false">Sonnenuntergang</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.dialogSelectMargin}>
              <InputLabel>Offset</InputLabel>
              <Select
                value={triggerDialogInputState.sun.offsetMin}
                onChange={handleTriggerSunOffsetChange}
              >
                {Array.from(Array(49), (_, i) => (i - 24) * 5).map((o) => (
                  <MenuItem value={o} key={o}>
                    {o > 0 && '+'}
                    {o} min
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Abbruch
        </Button>
        <Button onClick={handleAddTriggerClicked} color="primary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
