import React from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import {
  AutomationRuleServerInput,
  AUTOMATION_ATTRIBUTES,
  CreateAutomationData,
  CreateAutomationInput,
  CREATE_AUTOMATION_MUTATION,
} from '../../api/automation';
import AutomationCreateUpdate from './AutomationCreateUpdate';

export default function NewAutomation() {
  const history = useHistory();
  const historyGoAutomation = () => history.push('/app/automation');

  const [
    createAutomation,
    { error: createAutomationError, loading: createAutomationLoading },
  ] = useMutation<CreateAutomationData, CreateAutomationInput>(CREATE_AUTOMATION_MUTATION, {
    update(cache, { data }) {
      const newAutomation = data?.createAutomation;
      if (!newAutomation) return;
      cache.modify({
        fields: {
          listAutomations(existingAutomationRefs = []) {
            const newAutomationRef = cache.writeFragment({
              data: newAutomation,
              fragment: AUTOMATION_ATTRIBUTES,
            });
            return [...existingAutomationRefs, newAutomationRef];
          },
        },
      });
    },
  });

  const handleCreateAutomation = async (rule: AutomationRuleServerInput) => {
    try {
      await createAutomation({ variables: { rule } });
    } catch (err) {
      return;
    }
    historyGoAutomation();
  };

  return (
    <AutomationCreateUpdate
      onSubmit={handleCreateAutomation}
      gqlError={createAutomationError}
      gqlLoading={createAutomationLoading}
    />
  );
}
