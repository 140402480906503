import React from 'react';
import { Route, Switch } from 'react-router-dom';
import About from './About';
import CustomAppBar from '../layout/CustomAppBar';

const AboutLayout = () => (
  <div>
    <CustomAppBar forceUnauthenticated iconType="home" />
    <Switch>
      <Route path="/about" component={About} />
    </Switch>
  </div>
);
export default AboutLayout;
