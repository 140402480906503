import { gql } from '@apollo/client';

export const GENERAL_ATTRIBUTES = gql`
  fragment generalAttributes on General {
    isSetup
    latitude
    longitude
    houseName
    googleAssistantConnected
  }
`;

export type General = {
  isSetup: boolean;
  latitude: number;
  longitude: number;
  houseName: string;
  googleAssistantConnected: boolean;
};

export type GetGeneralData = {
  getGeneral: General;
};

export const GET_GENERAL_QUERY = gql`
  {
    getGeneral {
      ...generalAttributes
    }
  }
  ${GENERAL_ATTRIBUTES}
`;

export type SetupGeneralData = {
  setupGeneral: General;
};

export type SetupGeneralInput = {
  houseName: string;
  latitude: number;
  longitude: number;
};

export const SETUP_GENERAL_MUTATION = gql`
  mutation setupGeneral($houseName: String!, $latitude: Float!, $longitude: Float!) {
    setupGeneral(houseName: $houseName, latitude: $latitude, longitude: $longitude) {
      ...generalAttributes
    }
  }
  ${GENERAL_ATTRIBUTES}
`;

export type SetHouseNameGeneralData = {
  setHouseNameGeneral: General;
};

export type SetHouseNameGeneralInput = {
  houseName: string;
};

export const SET_HOUSE_NAME_GENERAL_MUTATION = gql`
  mutation setHouseNameGeneral($houseName: String!) {
    setupGeneral(houseName: $houseName) {
      ...generalAttributes
    }
  }
  ${GENERAL_ATTRIBUTES}
`;

export type SetLocationGeneralData = {
  setLocationGeneral: General;
};

export type SetLocationGeneralInput = {
  latitude: number;
  longitude: number;
};

export const SET_LOCATION_GENERAL_MUTATION = gql`
  mutation setLocationGeneral($latitude: Float!, $longitude: Float!) {
    setLocationGeneral(latitude: $latitude, longitude: $longitude) {
      ...generalAttributes
    }
  }
  ${GENERAL_ATTRIBUTES}
`;
