import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Popover,
  Typography,
  Avatar,
  Divider,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import { FaceOutlined as FaceIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useQuery } from '@apollo/client';
import { useAuthCtx } from '../../context/auth';
import { AuthApiUser } from '../../api/auth';
import { GetGeneralData, GET_GENERAL_QUERY } from '../../api/general';

const useStyles = makeStyles((theme) => ({
  popover: {
    // background: theme.custom.palette.profilePopColor,
    width: theme.spacing(40),
    borderRadius: theme.shape.borderRadius,
  },
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    paddingBottom: '0px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    margin: theme.spacing(1),
    background: theme.palette.background.default,
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
  userName: {
    // ...theme.custom.fontFamily.metropolis,
    fontSize: '1rem',
    fontWeight: 500,
  },
  userEmail: {
    // ...theme.custom.fontFamily.roboto,
    fontSize: '0.9rem',
  },
  bar: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSignout: {
    // ...theme.custom.fontFamily.metropolis,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 2),
    fontSize: '0.8rem',
    fontWeight: 500,
    textTransform: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    // color: '#fff',
  },
}));

type profilePopColorPropsT = {
  anchorEl: HTMLButtonElement | null;
  isOpen: boolean;
  onClose: () => void;
  user: AuthApiUser;
};

const ProfilePopover = ({ anchorEl, isOpen, onClose, user }: profilePopColorPropsT) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(false);
  const [logoutErrorState, setLogoutErrorState] = React.useState('');
  const { logout } = useAuthCtx();

  const { loading: getGeneralLoading, error: getGeneralError, data: getGeneralData } = useQuery<
    GetGeneralData
  >(GET_GENERAL_QUERY);
  if (getGeneralError) console.log(JSON.stringify(getGeneralError));
  if (getGeneralData) console.log(getGeneralData);

  const handleClick = async () => {
    console.log('performing logout');
    setIsLoading(true);
    onClose();
    const errorMsg = await logout();
    console.log('Logout performed:', errorMsg === '' ? true : `error: ${errorMsg}`);
    if (errorMsg !== '') {
      setIsLoading(false);
      setLogoutErrorState(errorMsg);
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason !== 'clickaway') setLogoutErrorState('');
  };

  const getHoudseNameString = () => {
    if (getGeneralError) return `Error: ${getGeneralError.message}`;
    if (getGeneralLoading) return 'Loading...';
    if (getGeneralData) return getGeneralData.getGeneral.houseName;
    return 'Error';
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={logoutErrorState !== ''} autoHideDuration={10000} onClose={handleClose}>
        <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
          {logoutErrorState}
        </Alert>
      </Snackbar>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        classes={{
          paper: classes.popover,
        }}
      >
        <div className={classes.container}>
          <Avatar className={classes.avatar}>
            <FaceIcon fontSize="large" color="secondary" />
          </Avatar>
          <div className={classes.userInfo}>
            <Typography
              className={classes.userName}
              variant="h6"
              component="span"
              color="textPrimary"
            >
              {getHoudseNameString()}
            </Typography>
            <Typography
              className={classes.userEmail}
              variant="body1"
              component="span"
              color="textSecondary"
            >
              {user.email}
            </Typography>
          </div>
        </div>
        {/* <div className={classes.bar}>
          <Button variant="outlined" size="small">
            Account verwalten
          </Button>
        </div> */}
        {/* <Divider />
        <div className={classes.bar}>Keine neuen Benachrichtugungen.</div> */}
        <Divider />
        <div className={classes.bar}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClick}
            classes={{ root: classes.buttonSignout }}
          >
            Logout
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default ProfilePopover;
