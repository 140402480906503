import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link, useHistory } from 'react-router-dom';
import { Button, Badge } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ProfilePopover from './ProfilePopover';
import { useAuthCtx } from '../../context/auth';
import HomeappLogo from '../../images/homeapp_logo_24px.png';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
  })
);

type AppBarBaseT = {
  forceUnauthenticated?: boolean;
  title?: string;
};
type AppBarIconT = {
  iconType?: 'back' | 'home' | 'add';
  onIconClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
const CustomAppBar = ({
  forceUnauthenticated = false,
  title = 'Homeapp',
  iconType,
  onIconClick,
}: AppBarBaseT & AppBarIconT) => {
  const classes = useStyles();

  const history = useHistory();
  const historyGoHome = () => {
    history.push('/app/home');
  };

  const { user } = useAuthCtx();

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openProfilePopover = (event: React.MouseEvent<HTMLButtonElement>) =>
    setPopoverAnchorEl(event.currentTarget);
  const closeProfilePopover = () => setPopoverAnchorEl(null);

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Toolbar>
          {/* {GetMainIcon({ iconType, onIconClick })} */}
          <IconButton edge="start" color="inherit" onClick={historyGoHome}>
            {/* <HomeIcon /> */}
            <img src={HomeappLogo} alt="Homeapp Logo" />
          </IconButton>
          <Typography color="inherit" variant="h6" className={classes.title}>
            {title}
          </Typography>
          {!forceUnauthenticated && user !== undefined ? (
            <>
              {/* <IconButton edge="end" color="inherit">
                <AddCircleIcon />
              </IconButton> */}
              <IconButton edge="end" onClick={openProfilePopover} color="inherit">
                <Badge badgeContent={4} color="secondary">
                  <AccountCircleIcon />
                </Badge>
              </IconButton>
              <ProfilePopover
                isOpen={Boolean(popoverAnchorEl)}
                anchorEl={popoverAnchorEl}
                onClose={closeProfilePopover}
                user={user}
              />
            </>
          ) : (
            <Button variant="contained" component={Link} to="/auth/signin" disableElevation>
              Anmelden
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default CustomAppBar;
