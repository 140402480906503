import React from 'react';
import { ThemeProvider, CssBaseline, useMediaQuery } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import theme from '../../utils/theme';
import AppRouter from './AppRouter';
import ApolloWrapper from '../../api/bridge';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const selectedTheme = React.useMemo(() => theme(prefersDarkMode), [prefersDarkMode]);

  return (
    <ThemeProvider theme={selectedTheme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ApolloWrapper>
          <AppRouter />
        </ApolloWrapper>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
export default App;
