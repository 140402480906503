import React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import HomeLayout from '../home/HomeLayout';
import AuthLayout from '../auth/AuthLayout';
import PoliciesLayout from '../policies/PoliciesLayout';
import AboutLayout from '../about/AboutLayout';
import ErrorLayout from '../errors/ErrorLayout';
import PrivateRoute from './PrivateRoute';
import SettingsLayout from '../settings/SettingsLayout';
import AutomationLayout from '../automation/AutomationLayout';
import SetupLayout from '../setup/SetupLayout';
import OAuth2Layout from '../oauth2/OAuth2Layout';

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect from="/" to="/app/home" exact />
        <Route path="/auth" component={AuthLayout} />
        <PrivateRoute path="/setup" component={SetupLayout} forceNotSetup />
        <PrivateRoute path="/app" component={HomeLayout} />
        <PrivateRoute path="/settings" component={SettingsLayout} />
        <PrivateRoute path="/automation" component={AutomationLayout} />
        <PrivateRoute path="/oauth2" component={OAuth2Layout} />
        <Route path="/policies" component={PoliciesLayout} />
        <Route path="/about" component={AboutLayout} />
        <Route path="*" component={ErrorLayout} />
      </Switch>
    </BrowserRouter>
  );
}
