import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import GeneralSetup from './GeneralSetup';

export default function SetupLayout() {
  return (
    <Switch>
      <Route path="/setup/general-setup" component={GeneralSetup} />
      <Redirect path="/setup" to="/setup/general-setup" />
    </Switch>
  );
}
