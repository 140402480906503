import gql from 'graphql-tag';
import { Thing, ThingTrait, THING_ATTRIBUTES } from './things';

export type PerformActionData = {
  performAction: Thing;
};

export type PerformActionInput = {
  action: {
    thingId: string;
    trait: ThingTrait;
    input: Record<string, any>;
  };
};

export const PERFORM_ACTION_MUTATION = gql`
  mutation performAction($action: ActionInput!) {
    performAction(action: $action) {
      ...thingAttributes
    }
  }
  ${THING_ATTRIBUTES}
`;
