import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    marginTop: {
      marginTop: '1%',
    },
  })
);

const About = () => {
  const classes = useStyles();

  return (
    <Container className={classes.marginTop} maxWidth="xs">
      <h2>About</h2>
      Bla bla bla!
      <br />
      <Button
        className={classes.marginTop}
        variant="contained"
        color="secondary"
        component={Link}
        to="/auth/signup"
      >
        Registrieren
      </Button>
      <br />
      <Button
        className={classes.marginTop}
        variant="contained"
        color="secondary"
        component={Link}
        to="/auth/signin"
      >
        Anmelden
      </Button>
      <br />
      <Button
        className={classes.marginTop}
        variant="contained"
        color="secondary"
        component={Link}
        to="/"
      >
        Home
      </Button>
    </Container>
  );
};
export default About;
