import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Container,
  Toolbar,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import { Alert, AlertTitle } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuid } from 'uuid';
import { ApolloError, useQuery } from '@apollo/client';
import * as Constants from '../../utils/constants';
import { ThingTrait, ListThingsData, LIST_THING_QUERY, extendedThingType } from '../../api/things';
import {
  AutomationActionServer,
  AutomationConditionServer,
  AutomationRuleServerInput,
  AutomationTriggerServer,
  ConditionOperator,
  ConditionOperatorToString,
  ConditionType,
  TriggerRecurrence,
  TriggerRecurrenceToString,
  TriggerType,
  TriggerWeekdayMapToString,
} from '../../api/automation';
import ConditionHelper, {
  ConditionPropertyKeyToString,
  ConditionPropertyKeyUnitStr,
} from './ConditionHelper';
import TriggerHelper from './TriggerHelper';
import ActionHelper from './ActionHelper';

enum OpenDialog {
  None,
  Trigger,
  Conditions,
  Actions,
}

type ErrorState =
  | undefined
  | 'trigger'
  | 'condition'
  | 'action'
  | 'triggerEmpty'
  | 'actionEmpty'
  | 'defaultValue';

function ErrorToLabel(eType: ErrorState) {
  switch (eType) {
    case 'trigger':
    case 'triggerEmpty':
      return 'Trigger Fehler';
    case 'condition':
      return 'Bedingung Fehler';
    case 'action':
    case 'actionEmpty':
      return 'Aktion Fehler';
    case 'defaultValue':
      return 'Default Wert Fehler';
    default:
      return 'Unbekanter Fehler';
  }
}

function ErrorToDescription(eType: ErrorState) {
  switch (eType) {
    case 'trigger':
      return 'Fehler beim hinzufügen des Triggers.';
    case 'condition':
      return 'Fehler beim hinzufügen der Bedingung.';
    case 'action':
      return 'Fehler beim hinzufügen der Aktion.';
    case 'triggerEmpty':
      return 'Trigger darf nicht leer sein.';
    case 'actionEmpty':
      return 'Aktionen darf nicht leer sein.';
    case 'defaultValue':
      return 'Fehler beim parsen des default wertes.';
    default:
      return 'Ein unbekannter Fehler ist aufgetreten bitte erneut versuchen.';
  }
}

type TriggerMap = Record<string, AutomationTriggerServer>;
type ConditionMap = Record<string, AutomationConditionServer>;
type ActionMap = Record<string, AutomationActionServer>;

type FormikCreateAutomationInput = {
  automationName: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(4),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    headingAccordion: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
    },
    cardAutomation: {
      marginTop: 16,
    },
    dialogSelectMargin: {
      marginTop: 10,
    },
    loadingIndicator: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '5%',
    },
  })
);

type AutomationCreateUpdateProps = {
  defaultValue?: AutomationRuleServerInput;
  gqlError: ApolloError | undefined;
  gqlLoading: boolean;
  hideForm?: boolean;
  onSubmit: (rule: AutomationRuleServerInput) => Promise<void>;
};

export default function AutomationCreateUpdate({
  defaultValue,
  gqlError,
  gqlLoading,
  hideForm = false,
  onSubmit,
}: AutomationCreateUpdateProps) {
  const classes = useStyles();

  const history = useHistory();
  const historyGoAutomation = () => history.push('/app/automation');

  const { loading: listThingsLoading, error: listThingsError, data: listThingsData } = useQuery<
    ListThingsData,
    undefined
  >(LIST_THING_QUERY);
  if (listThingsError) console.log(JSON.stringify(listThingsError));
  if (listThingsData) console.log(listThingsData);

  const getThingById = (id: string) => {
    return listThingsData?.listThings?.find((thing) => thing.id === id);
  };

  const triggerToDescription = (trigger: AutomationTriggerServer) => {
    switch (trigger.type) {
      case TriggerType.Time:
        if (!trigger.time || !(trigger.time.time instanceof Date))
          return 'Fehlerhafter zeit trigger';
        switch (trigger.time.recurrence) {
          case TriggerRecurrence.Dayly:
            return `${TriggerRecurrenceToString(
              trigger.time.recurrence
            )} um ${trigger.time.time.toLocaleTimeString('de-DE', {
              hour: '2-digit',
              minute: '2-digit',
            })} Uhr`;
          case TriggerRecurrence.Weekly:
            return `${TriggerRecurrenceToString(
              trigger.time.recurrence
            )} (${TriggerWeekdayMapToString(
              trigger.time.weekdays
            )}) um ${trigger.time.time.toLocaleTimeString('de-DE', {
              hour: '2-digit',
              minute: '2-digit',
            })} Uhr`;
          case TriggerRecurrence.Minutely:
          case TriggerRecurrence.Hourly:
            return TriggerRecurrenceToString(trigger.time.recurrence);
          default:
            return 'Fehler: unbekanntes Trigger Zeitintervall!';
        }
      case TriggerType.Sun:
        if (!trigger.sun) return '';
        return `${trigger.sun.sunrise ? 'Sonnenaufgang' : 'Sonnenuntergang'} ${
          trigger.sun.offsetMin === 0
            ? ''
            : `${trigger.sun.offsetMin > 0 ? '+' : ''}${trigger.sun.offsetMin} min`
        }`;
      default:
        return 'Fehler: unbekannter Triggertyp!';
    }
  };
  const conditionToDescription = (condition: AutomationConditionServer) => {
    switch (condition.type) {
      case ConditionType.Device:
        if (!condition.device) return 'Fehler: unvollständige gerätebedingung!!';
        return `${ConditionPropertyKeyToString(condition.device.propertyKey)} (${
          getThingById(condition.device.deviceId)?.name ?? 'Error: Invalid device!'
        }) ${ConditionOperatorToString(condition.device.operator)} ${
          condition.device.value
        }${ConditionPropertyKeyUnitStr(condition.device.propertyKey)}`;
      default:
        return 'Fehler: unbekannter Bedingungstyptyp!';
    }
  };
  const actionToDescription = (action: AutomationActionServer) => {
    const assThing = getThingById(action.deviceId);
    if (!action.trait || !assThing) return 'Error ActionDescription: incomplete action';
    const extendedThingName = `${assThing.name} (${extendedThingType(assThing)})`;
    switch (action.trait) {
      case ThingTrait.OnOff:
        return `${extendedThingName} => ${
          action.input.on === true ? 'einschalten' : 'ausschalten'
        }`;
      case ThingTrait.Brightness:
        return `${extendedThingName} dimmen auf ${action.input.brightness}%`;
      case ThingTrait.OpenClose:
        return `${extendedThingName} => ${action.input.open === true ? 'öffnen' : 'schließen'}${
          action.input.duration > 0 ? ` für ${action.input.duration} sec` : ''
        }`;
      case ThingTrait.FanSpeed:
        return `${extendedThingName} => Stufe ${action.input.fanSpeed}`;
      default:
        return 'Fehler: unbekannter Actionstyp!';
    }
  };

  const [openDialogState, setOpenDialogState] = useState(OpenDialog.None);
  const handleCloseDialog = () => setOpenDialogState(OpenDialog.None);

  const [errorState, seterrorState] = useState<ErrorState>(undefined);

  const handleHelperError = (err: ErrorState) => {
    seterrorState(err);
    setOpenDialogState(OpenDialog.None);
  };

  const [triggerMapState, setTriggerMapState] = useState<TriggerMap>({});
  const addTrigger = (trigger: AutomationTriggerServer) => {
    setTriggerMapState({ ...triggerMapState, [uuid()]: trigger });
    seterrorState(undefined);
    setOpenDialogState(OpenDialog.None);
  };
  const removeTrigger = (key: string) => {
    const newTriggerMap = { ...triggerMapState };
    delete newTriggerMap[key];
    setTriggerMapState(newTriggerMap);
  };
  const [conditionMapState, setConditionMapState] = useState<ConditionMap>({});
  const addCondition = (condition: AutomationConditionServer) => {
    setConditionMapState({ ...conditionMapState, [uuid()]: condition });
    seterrorState(undefined);
    setOpenDialogState(OpenDialog.None);
  };
  const removeCondition = (key: string) => {
    const newConditionMap = { ...conditionMapState };
    delete newConditionMap[key];
    setConditionMapState(newConditionMap);
  };
  const [actionMapState, setActionMapState] = useState<ActionMap>({});
  const addAction = (action: AutomationActionServer) => {
    setActionMapState({ ...actionMapState, [uuid()]: action });
    seterrorState(undefined);
    setOpenDialogState(OpenDialog.None);
  };
  const removeAction = (key: string) => {
    const newActionMap = { ...actionMapState };
    delete newActionMap[key];
    setActionMapState(newActionMap);
  };

  useEffect(() => {
    if (defaultValue) {
      setTriggerMapState(
        defaultValue.trigger.reduce<TriggerMap>((acc, val) => {
          let newTrigger: AutomationTriggerServer | undefined;
          switch (val.type) {
            case TriggerType.Time: {
              const time = new Date(val.time?.time as any);
              if (
                val.time !== undefined &&
                !Number.isNaN(Number(time)) &&
                Object.values(TriggerRecurrence).includes(val.time.recurrence) &&
                val.time.weekdays !== undefined
              ) {
                newTrigger = {
                  type: TriggerType.Time,
                  time: {
                    time,
                    recurrence: val.time.recurrence,
                    weekdays: val.time.weekdays,
                  },
                };
              }
              break;
            }
            case TriggerType.Sun:
              if (
                val.sun !== undefined &&
                val.sun.offsetMin !== undefined &&
                val.sun.sunrise !== undefined
              ) {
                newTrigger = {
                  type: TriggerType.Sun,
                  sun: {
                    offsetMin: val.sun.offsetMin,
                    sunrise: val.sun.sunrise,
                  },
                };
              } else console.log('Trigger invalid: ', val);
              break;
            default:
              console.log('Trigger default invalid type: ', val.type);
          }
          if (newTrigger) acc[uuid()] = newTrigger;
          else seterrorState('defaultValue');
          return acc;
        }, {})
      );
      setConditionMapState(
        defaultValue.conditions.reduce<ConditionMap>((acc, val) => {
          let newCondition: AutomationConditionServer | undefined;
          switch (val.type) {
            case ConditionType.Device:
              if (
                val.device !== undefined &&
                val.device.deviceId !== undefined &&
                Object.values(ConditionOperator).includes(val.device.operator) &&
                val.device.propertyKey !== undefined &&
                val.device.value !== undefined
              ) {
                newCondition = {
                  type: ConditionType.Device,
                  device: {
                    deviceId: val.device.deviceId,
                    operator: val.device.operator,
                    propertyKey: val.device.propertyKey,
                    value: val.device.value,
                  },
                };
              } else console.log('Condition invalid: ', val);
              break;
            default:
              console.log('Condition default invalid type: ', val.type);
          }
          if (newCondition) acc[uuid()] = newCondition;
          else seterrorState('defaultValue');
          return acc;
        }, {})
      );
      setActionMapState(
        defaultValue.actions.reduce<ActionMap>((acc, val) => {
          let newAction: AutomationActionServer | undefined;
          if (val.deviceId !== undefined && val.input !== undefined && val.trait !== undefined) {
            newAction = {
              deviceId: val.deviceId,
              input: val.input,
              trait: val.trait,
            };
          } else console.log('Action invalid: ', val);
          if (newAction) acc[uuid()] = newAction;
          else seterrorState('defaultValue');
          return acc;
        }, {})
      );
    }
  }, [defaultValue]);

  const submitAutomationData = async (
    input: FormikCreateAutomationInput,
    { setSubmitting }: FormikHelpers<FormikCreateAutomationInput>
  ) => {
    console.log('Submit', input);
    if (Object.keys(triggerMapState).length === 0) seterrorState('triggerEmpty');
    else if (Object.keys(actionMapState).length === 0) seterrorState('actionEmpty');
    else {
      const ruleInput: AutomationRuleServerInput = {
        name: input.automationName,
        trigger: Object.values(triggerMapState),
        conditions: Object.values(conditionMapState),
        actions: Object.values(actionMapState),
      };
      console.log('Final data:', ruleInput);
      await onSubmit(ruleInput);
    }
    setSubmitting(false);
  };

  const validate = (input: FormikCreateAutomationInput) => {
    const errors: FormikErrors<FormikCreateAutomationInput> = {};
    if (input.automationName === '') errors.automationName = 'Name des Regel eingeben.';
    else if (input.automationName.length > 50) errors.automationName = 'Maximal 50 Zeichen.';
    else if (!Constants.LetterNumberSpaceRegex.test(input.automationName))
      errors.automationName = 'Nur Buchstaben, Zahlen und Leerzeichen zulässig.';
    return errors;
  };

  return (
    <>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={historyGoAutomation}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {defaultValue === undefined
            ? 'Neue Automatisierungsregel'
            : 'Automatisierungsregel bearbeiten'}
        </Typography>
      </Toolbar>
      <Container maxWidth="md" className={classes.container}>
        {(gqlError || errorState || listThingsError) && (
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  seterrorState(undefined);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>
              {(gqlError && 'Graphql Error') ??
                (listThingsError && 'get things error') ??
                ErrorToLabel(errorState)}
            </AlertTitle>
            {gqlError?.message ?? listThingsError?.message ?? ErrorToDescription(errorState)}
          </Alert>
        )}
        {listThingsLoading && (
          <div className={classes.loadingIndicator}>
            <CircularProgress />
          </div>
        )}
        {!hideForm && errorState !== 'defaultValue' && listThingsData && (
          <Formik
            initialValues={{ automationName: defaultValue?.name ?? '' }}
            onSubmit={submitAutomationData}
            validate={validate}
          >
            {({ values, errors, touched, isSubmitting, handleSubmit, handleChange }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Backdrop className={classes.backdrop} open={isSubmitting || gqlLoading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
                <TriggerHelper
                  dialogOpen={openDialogState === OpenDialog.Trigger}
                  handleCloseDialog={handleCloseDialog}
                  addTrigger={addTrigger}
                  availableThings={listThingsData.listThings}
                  handleError={() => handleHelperError('trigger')}
                />
                <ConditionHelper
                  dialogOpen={openDialogState === OpenDialog.Conditions}
                  handleCloseDialog={handleCloseDialog}
                  addCondition={addCondition}
                  availableThings={listThingsData.listThings}
                  handleError={() => handleHelperError('condition')}
                />
                <ActionHelper
                  dialogOpen={openDialogState === OpenDialog.Actions}
                  handleCloseDialog={handleCloseDialog}
                  addAction={addAction}
                  availableThings={listThingsData.listThings}
                  handleError={() => handleHelperError('action')}
                />
                <TextField
                  autoFocus
                  required
                  fullWidth
                  margin="normal"
                  name="automationName"
                  label="Name der Regel"
                  autoComplete="off"
                  variant="outlined"
                  value={values.automationName}
                  onChange={handleChange}
                  error={errors.automationName !== undefined && touched.automationName}
                  helperText={
                    errors.automationName && touched.automationName && errors.automationName
                  }
                />
                <Card className={classes.cardAutomation}>
                  <CardHeader
                    action={
                      <IconButton onClick={() => setOpenDialogState(OpenDialog.Trigger)}>
                        <AddIcon />
                      </IconButton>
                    }
                    title="Trigger"
                    subheader="Trigger welche zu einer Prüfung der Bedingungen und bei Erfolg zum ausführen der Aktion(en) führen."
                    subheaderTypographyProps={{
                      variant: 'body2',
                      color: 'textSecondary',
                      component: 'p',
                    }}
                  />

                  {Object.keys(triggerMapState).length !== 0 && (
                    <CardContent>
                      <List>
                        {Object.entries(triggerMapState).map(([key, trigger]) => (
                          <ListItem key={key}>
                            <ListItemText primary={triggerToDescription(trigger)} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => removeTrigger(key)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  )}
                </Card>
                <Card className={classes.cardAutomation}>
                  <CardHeader
                    action={
                      <IconButton onClick={() => setOpenDialogState(OpenDialog.Conditions)}>
                        <AddIcon />
                      </IconButton>
                    }
                    title="Bedingungen"
                    subheader="Bedingungen die erfüllt sein müssen damit die Aktionen ausgeführt werden. (optional)"
                    subheaderTypographyProps={{
                      variant: 'body2',
                      color: 'textSecondary',
                      component: 'p',
                    }}
                  />

                  {Object.keys(conditionMapState).length !== 0 && (
                    <CardContent>
                      <List>
                        {Object.entries(conditionMapState).map(([key, condition]) => (
                          <ListItem key={key}>
                            <ListItemText primary={conditionToDescription(condition)} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => removeCondition(key)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  )}
                </Card>
                <Card className={classes.cardAutomation}>
                  <CardHeader
                    action={
                      <IconButton onClick={() => setOpenDialogState(OpenDialog.Actions)}>
                        <AddIcon />
                      </IconButton>
                    }
                    title="Aktionen"
                    subheader="Aktionen die ausgeführt werden bei erfolgreicher Prüfung der Bedingungen."
                    subheaderTypographyProps={{
                      variant: 'body2',
                      color: 'textSecondary',
                      component: 'p',
                    }}
                  />

                  {Object.keys(actionMapState).length !== 0 && (
                    <CardContent>
                      <List>
                        {Object.entries(actionMapState).map(([key, action]) => (
                          <ListItem key={key}>
                            <ListItemText primary={actionToDescription(action)} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => removeAction(key)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  )}
                </Card>
                <Button
                  className={classes.cardAutomation}
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Speichern
                </Button>
              </form>
            )}
          </Formik>
        )}
      </Container>
    </>
  );
}
