import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core';
import * as Constants from '../../utils/constants';

const useStyles = makeStyles(() =>
  createStyles({
    mainContainer: {
      marginTop: '10%',
    },
  })
);

const Copyright = () => {
  const classes = useStyles();

  return (
    <Container className={classes.mainContainer} component="main" maxWidth="md">
      <Typography variant="body1" color="textPrimary" align="center">
        All files and information contained in this Website or Blog are copyright by{' '}
        {Constants.FullAppName}, and may not be duplicated, copied, modified or adapted, in any way
        without our written permission. Our Website or Blog may contain our service marks or
        trademarks as well as those of our affiliates or other companies, in the form of words,
        graphics, and logos. Your use of our Website, Blog or Services does not constitute any right
        or license for you to use our service marks or trademarks, without the prior written
        permission of
        {Constants.FullAppName}
        . Our Content, as found within our Website, Blog and Services, is protected under United
        States and foreign copyrights. The copying, redistribution, use or publication by you of any
        such Content, is strictly prohibited. Your use of our Website and Services does not grant
        you any ownership rights to our Content.
        <br />
        <br />
        Copyright © {Constants.FullAppName}
        {new Date().getFullYear()}. All Rights Reserved.
        {Constants.AppDomain}
      </Typography>
    </Container>
  );
};
export default Copyright;
